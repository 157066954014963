import React, { useEffect } from "react";

// Images
import ape from "../../assets/images/ape.png";

// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import { useGetNamesQuery } from "../../sms/slices/api";
const TreehouseGalleryApe = (props) => {
  const { data, refetch } = useGetNamesQuery({});

  useEffect(() => {
    const it = setInterval(() => {
      refetch();
    }, 30000);
    return () => {
      clearInterval(it);
    };
  }, []);

  return (
    <section className="bp-tree-ape">
      <Container>
        <Row>
          <Col md={12}>
            <ul>
              {data &&
                data.map((ape, idx) => (
                  <li key={idx}>
                    <span className="bp-tree-apeid">ID #{ape.id}</span>
                    <img src={ape?.image} alt="ape" />
                    <span className="bp-tree-apena">{ape.name}</span>
                  </li>
                ))}

              {/* 		    
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li>
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li>
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li>
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li>
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li>
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li>
		    	<li>
		    		<span className="bp-tree-apeid">ID #231</span>
		    			<img src={ape} alt="ape"/>
		    		<span className="bp-tree-apena">Sam the Brave</span>
		    	</li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TreehouseGalleryApe;
