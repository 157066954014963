import { useEffect } from "react";

import { toast } from "react-toastify";
import swal from "sweetalert";
import { POLYGON_CHAIN_ID } from "../utils/constants";
import { useCustomWeb3React } from "./useCustomWeb3React";

export const useToaster = () => {
  const { account, chainId } = useCustomWeb3React();

  const fireToast = (method) => {
    toast[method](
      `You are ${method === "error" ? "not" : ""} connected to mainnet`,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      }
    );
  };

  useEffect(() => {
    if (account) {
      if (chainId !== POLYGON_CHAIN_ID) {
        return fireToast("error");
      }
      fireToast("success");
    }
  }, [chainId, account]);

  useEffect(() => {
    if (typeof window !== "undefined" && (!window?.web3 || !window?.ethereum)) {
      swal(
        "",
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        "error"
      );
    }
  }, []);

  return {
    account,
    chainId,
  };
};
