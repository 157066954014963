import React from 'react';

// Components
import Layout from "../../components/Layout/Layout";

import BananaBARBanner from "../../components/BananaBARBanner/BananaBARBanner";
import BananaBARMoreInfo from "../../components/BananaBARMoreInfo/BananaBARMoreInfo";
import BananaBARSmartContract from "../../components/BananaBARSmartContract/BananaBARSmartContract";
import BananaBARPolygonContract from "../../components/BananaBARPolygonContract/BananaBARPolygonContract";

const BananaBAR = () => {
  return (
  	<Layout>
  		<BananaBARBanner/>
  		<BananaBARMoreInfo/>
  		<BananaBARSmartContract/>
  		<BananaBARPolygonContract/>
  	</Layout>
	) 
}

export default BananaBAR;