import React from 'react';

//Images
//import footerlogo from '../../assets/images/footer-logo.png';
import footerlogo from '../../assets/images/footer-115x115.png';
import opensea from "../../assets/images/opensealogo-img.png";
import discord from "../../assets/images/discord-img.png";
import twitter from "../../assets/images/twitter-icon.png";
import instagram from "../../assets/images/instagram-img.png";

// Style
import "./style.css";

const Footer = (props) => {
	return(
		<footer>
		<a href="/" className="footer_logo_main"><img src={footerlogo} alt="Logo"/></a>
		<a href="https://polygonscan.com/address/0xff35d2aaa7e7c5e3ad0d81d7e621086c642ab74d" target="_blank" rel="noreferrer" className="verified_link d-block"> Apevolution Club Contract</a>
		<ul>
			<li>
				<a href="https://www.instagram.com/babyapesrescue/" target="_blank" rel="noreferrer"><img src={instagram} alt="Instagram"/></a>
			</li>
			<li>
				<a href="https://twitter.com/ApevolutionClub" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter"/></a>
			</li>
				<li>
			<a href="https://discord.gg/M4MnHtnjFn" target="_blank" rel="noreferrer"><img src={discord} alt="Discord"/></a>
			</li>
			<li>
				<a href="https://opensea.io/collection/apevolution-club" target="_blank" rel="noreferrer"><img src={opensea} alt="Opensea"/></a>
			</li>
		</ul>
		<ul className="bp-useful-link">
		    <li><a href="https://raritysniper.com/nft-drops-calendar" target="_blank">NFT Drops </a></li>
			<li><a href="#"> | </a></li>
			<li><a href="/terms-of-service">Terms of Service </a></li>
			<li><a href="#"> | </a></li>
			<li><a href="/privacy-policy">Privacy Policy </a></li>
		</ul>
		</footer>
		)
}

export default Footer;


