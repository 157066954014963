import React from "react";

// Images
import bar from "../../assets/images/barsymbolitth.png";
import greenbann from "../../assets/images/greenbann.png";

// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import { useTokenContract } from "../../sms/hooks/token-contract";
const InsidetheTreehouse = (props) => {
  const {
    claimRewards,
    balance,

    walletBalance: {
      totalClaimable,
      generatedByOG,
      generatedByCloneAndMad,
      generatedByMonster,
    },
  } = useTokenContract();
  return (
    <section className="bp-itth-main">
      <Container>
        <Row>
          <Col md={7}>
            <h1>Inside the Treehouse</h1>
            <p>
              The area is exclusively for members only. You can click on any of
              your NFT image below to change your name, birthdate or bio.
            </p>
          </Col>
          <Col md={5}>
            <div className="bp-itth-inbx">
              <div className="bp-itth-inbx-head">
                <h2 style={{ fontSize: "0.9rem" }}>
                  Your Wallet Balance
                  <span>
                    <img src={bar} style={{ width: 25 }} alt="bar" />
                    {balance}
                  </span>
                </h2>
              </div>
              <div className="bp-itth-inbx-cont v2">
                <ul>
                  <li>
                    <h4>Total Available to Claim</h4>
                    <h3>{totalClaimable} $BAR</h3>
                  </li>
                  <li>
                    <h4>Generated by OG</h4>
                    <h3>{generatedByOG} $BAR</h3>
                  </li>
                  <li>
                    <h4>Generated by Clone & Mad Scientist</h4>
                    <h3>{generatedByCloneAndMad} $BAR</h3>
                  </li>
                  <li>
                    <h4>Generated by Monster</h4>
                    <h3>{generatedByMonster} $BAR</h3>
                  </li>
                </ul>
              </div>

              <div className="bp-itth-inbx-btn">
                <a href="#." onClick={claimRewards} className="bp-itth-btn">
                  <img src={greenbann} alt="icon" />
                  Claim $BAR
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InsidetheTreehouse;
