import React from 'react';

// Components
import Layout from "../../components/Layout/Layout";
import Banner from "../../components/Banner/Banner";
import Video from "../../components/Video/Video";
import ApesRescue from "../../components/ApesRescue/ApesRescue";
//import FairDistribution from "../../components/FairDistribution/FairDistribution";
import FeaturedOn from "../../components/FeaturedOn/FeaturedOn";
import ApevolutionClubCollection from "../../components/ApevolutionClubCollection/ApevolutionClubCollection";
//import RescueInfo from "../../components/RescueInfo/RescueInfo";
//import MoreRarity from "../../components/MoreRarity/MoreRarity";
import TheTeam from "../../components/TheTeam/TheTeam";
import MovingBanner from "../../components/MovingBanner/MovingBanner";
import Roadmap from "../../components/Roadmap/Roadmap";
//import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import VideoBanner from "../../components/VideoBanner/VideoBanner";
import Treehouse from "../../components/Treehouse/Treehouse";



const Home = () => {
  return (
  	<Layout>
  		<Banner/>
      <Video/>
  		<ApesRescue/>
      {/*
  		<FairDistribution/>
      */ }
  		<FeaturedOn/>
      <ApevolutionClubCollection/>
      {/*
      <RescueInfo/>
      <MoreRarity/>
      */ }
  		<MovingBanner/>
  		<Roadmap/>
  		<Treehouse/>
  		<VideoBanner/>
      {/*
  		  <FrequentlyAskedQuestions/>
      */ }
  		<TheTeam/>
  	</Layout>
	) 
}

export default Home;