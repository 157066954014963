import React from 'react';

// Images
import member1 from '../../assets/images/member-1.png';
import member2 from '../../assets/images/member-2.jpg';
import member3 from '../../assets/images/member-3.jpg';
import member4 from '../../assets/images/member-4.jpg';
import member5 from '../../assets/images/member-5.jpg';

// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const TheTeam = (props) => {
	return(
		<section className="bp-home-team" id="team">
		<Container>
		<Row>
		    <Col md={12}>
		    	<h2>The Team</h2>
		    </Col>
		  </Row>
		  <Row>
		    <div className="bp-home-team-sec">
		    	<img src={member1} alt="member1"/> 
		    	<h3>DamienE</h3>
		    	<p>Champion Artist</p>
		    	<p>The Workout Maniac</p>
		    </div>
		    <div className="bp-home-team-sec">
		    	<img src={member2} alt="member1"/> 
		    	<h3>GaryBaby</h3>
		    	<p>Creative Director</p>
		    	<p>YOLO Experimenting</p>
		    </div>
		    <div className="bp-home-team-sec">
		    	<img src={member3} alt="member1"/> 
		    	<h3>BabySage</h3>
		    	<p>Crypto Freak</p>
		    	<p>NFT Enthusiast</p>
		    </div>
		    <div className="bp-home-team-sec">
		    	<img src={member4} alt="member1"/> 
		    	<h3>SandraA</h3>
		    	<p>Mad Artist</p>
		    	<p>Morning Coffee Addict</p>
		    </div>
		    <div className="bp-home-team-sec">
		    	<img src={member5} alt="member1"/> 
		    	<h3>RachelK</h3>
		    	<p>The Outreach Gal</p>
		    	<p>Secretive Closet Hoarder</p>
		    </div>
		  </Row>
		 </Container>
		</section>
		)
}

export default TheTeam;