import React from 'react';

// Style
import "./video.css";
import discordimg from '../../assets/images/discordimg.png';
import { Container,Row, Col} from 'reactstrap';
import VideoPlayer from "react-video-js-player";
import PosterImg from '../../assets/images/cloninglaboratory.jpg';
//import Videohome from '../../assets/video/CloneLab.mp4';
import Videohome from '../../assets/video/Apevolution Club Coming Soon.mp4';

const Video = (props) => {
	const videoSrc = Videohome;
	const poster = PosterImg;
	return(
		<section className="bp-video-sec">
			<Container>
			    <div className="video-cont">
					<p className="text-white">Yield daily $BAR rewards without staking, mint strange ape species, 
					steal someone’s NFT & get 50% of secondary OS royalties if you HODL.</p>
				</div>
				<Row>
				    <div className="col-md-8 offset-md-2">
				        <VideoPlayer src={videoSrc} poster={poster} width="600" height="400" />
					</div>
		  		</Row>
			</Container>
		</section>
		)
}

export default Video;