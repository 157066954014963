import { useWeb3React } from "@web3-react/core";

export const useCustomWeb3React = () => {
  const {
    account: oAccount,
    chainId: oChainId,
    activate: oActivate,
    deactivate: oDeactivate,
    connector,
    library,
    active,
  } = useWeb3React();

  const status = localStorage.getItem("status");
  const account = status == "connected" && oAccount ? oAccount : undefined;
  const chainId = status == "connected" && oChainId ? oChainId : undefined;

  const deactivate = () => {
    localStorage.setItem("status", "disconnected");
    oDeactivate();
  };

  const activate = async (
    connector,
    errorCallBack = () => {},
    errorThrow = false
  ) => {
    localStorage.setItem("status", "connected");
    await oActivate(connector, errorCallBack, errorThrow);
  };

  return {
    account,
    chainId,
    connector,
    library,
    activate,
    deactivate,
    active,
  };
};
