import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
// Images
//import sitelogo from "../../assets/images/logo.png";
import sitelogo from "../../assets/images/header-90x90.png";
import opensea from "../../assets/images/opensealogo-img.png";
import discord from "../../assets/images/discord-img.png";
import twitter from "../../assets/images/twitter-icon.png";
import instagram from "../../assets/images/instagram-img.png";
import metamask from "../../assets/images/metamask.png";

import WalletConnectModal from "./WalletConnectModal";
// Style
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import "./style.css";
import {
  useConnectWallet,
  useConnectWalletConnector,
} from "../../contracts/getContracts";

import swal from "sweetalert";
import { getErrorMessage } from "../../utils/functions";
import { useCustomWeb3React } from "../../hooks/useCustomWeb3React";

const Header = (props) => {
  const [isActive, setActive] = useState("false");
  const [ishideShow, setIsHideShow] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const { account, deactivate } = useCustomWeb3React();

  const connectWallet = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (account) {
      setIsHideShow(true);
    } else {
      setIsHideShow(false);
    }
  }, [account]);

  return (
    <header>
      <Container>
        <div className="bp-main-sec">
          <div className="bp-main-logo-sec">
            <div className="bp-main-logo">
                <a href="/"><img src={sitelogo} alt="Logo" /></a>
            </div>
          </div>
          <div className="bp-main-nav-sec">
            <div className="bp-main-nav">
              <Nav className="bp-main-menu">
                <NavItem>
                  <NavLink href="/migration">Migration</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/insidethetreehouse">Treehouse</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/treehouse-gallery">Gallery</NavLink>
                </NavItem>
                   
                <NavItem>
                  <NavLink href="/cloning-laboratory">Laboratory</NavLink>
                </NavItem>
                 
                <NavItem>
                  <NavLink href="/bananabar">Banana BAR</NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="bp-main-soic">
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/babyapesrescue/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/ApevolutionClub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.gg/M4MnHtnjFn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={discord} alt="Discord" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://opensea.io/collection/apevolution-club"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={opensea} alt="Opensea" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="bp-main-mm">
              {ishideShow && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={deactivate}
                  className={ishideShow ? "hide-show" : ""}
                >
                  <div className="disconnect-wrapper">
                    <img src={metamask} alt="metamask" />{" "}
                    {account
                      ? account.slice(0, 6) +
                        "..." +
                        account.slice(account.length - 4)
                      : "0*x21...120219"}
                  </div>
                  <div className="hide">Disconnect</div>
                </div>
              )}

              {!ishideShow && (
                <>
                  <a
                    href="javascript:void(0)"
                    onClick={connectWallet}
                    className="bp-main-mm-desk"
                  >
                    <img src={metamask} alt="metamask" />{" "}
                    {account
                      ? account.slice(0, 6) +
                        "..." +
                        account.slice(account.length - 4)
                      : "Connect MetaMask"}
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={connectWallet}
                    className="bp-main-mm-mob"
                  >
                    {account
                      ? account.slice(0, 6) +
                        "..." +
                        account.slice(account.length - 4)
                      : "Connect"}
                  </a>
                </>
              )}
              <WalletConnectModal isOpen={isOpen} setOpen={setOpen} />
            </div>
          </div>
        </div>

        <div className="menu-btn mph-home-mobile-menu">
          <span onClick={handleToggle}>☰</span>
          <div
            className={isActive ? "overlay-close" : "overlay-open"}
            id="mySideoverlay"
            onClick={handleToggle}
          ></div>
        </div>
        <div id="mySidenav" className={isActive ? "mm-close" : "mm-open"}>
          <div className="bp-main-menu-mob">
            <ul className="side-bar-menu">
              <li>
                <a href="/migration">Migration</a>
              </li>
              <li>
                <a href="/insidethetreehouse">Treehouse</a>
              </li>
              <li>
                <a href="/treehouse-gallery">Gallery</a>
              </li>
              <li>
                <a href="/cloning-laboratory">Laboratory</a>
              </li>
              <li>
                <a href="/bananabar">Banana BAR</a>
              </li>
            </ul>
          </div>
          <div className="bp-main-soic-mob">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/babyapesrescue/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/babyapesrescue"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/M4MnHtnjFn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={discord} alt="Discord" />
                </a>
              </li>
              <li>
                <a
                  href="https://opensea.io/collection/apevolution-club"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={opensea} alt="Opensea" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
