import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "./style.css";
import metamask1 from "../../assets/images/metamask-fox 1.png";
import walletconnect from "../../assets/images/wallet-connect.png";
import {
  useConnectWallet,
  useConnectWalletConnector,
} from "../../contracts/getContracts";
import swal from "sweetalert";
import { getErrorMessage } from "../../utils/functions";

const WalletConnect = (props) => {
  const { isOpen, setOpen } = props;

  const toggle = () => setOpen(false);

  const callBackConnect = (success, err) => {
    if (err) swal("", getErrorMessage(err), "error");
    toggle();
  };

  const connect = useConnectWallet();
  const connectWC = useConnectWalletConnector();

  const connectWallet = async () => {
    // setOpen(true);
    try {
      await connect(callBackConnect);
    } catch (e) {
      //   console.log(getErrorMessage(e));
    }
  };

  const connectWalletWC = async () => {
    try {
      await connectWC(callBackConnect);
    } catch (e) {
      //   console.log(getErrorMessage(e));
    }
  };

  // useEffect(() => {
  //   console.log(isOpen);
  // }, [isOpen]);

  return (
    <Popup open={isOpen} modal>
      <div className="modal">
        <button className="close" onClick={toggle}>
          &times;
        </button>
        <div className="content bp-treepg-pop">
          <div onClick={connectWallet} className="row">
            <div className="md-12">
              <div className="connect-wallet">
                <img src={metamask1} alt="metamask" />
                <p>
                  <b>MetaMask</b>
                </p>
                <p className="grey-text">Connect to your MetaMask Wallet</p>
              </div>
            </div>
          </div>
          <div onClick={connectWalletWC} className="row bor">
            <div className="md-12">
              <div className="connect-wallet">
                <img src={walletconnect} alt="metamask" />
                <p>
                  <b>WalletConnect</b>
                </p>
                <p className="grey-text">Scan with WalletConnect to connect</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default WalletConnect;
