import React from "react";
import Popup from "reactjs-popup";

// Images
import foximg from "../../assets/images/metamask-fox 1.png";
import Logomark from "../../assets/images/Logomark-Blue 1.png";

// Style
import "./style.css";
import { useState } from "react";
import WalletConnect from "../Layout/WalletConnectModal";
const TreehousePreGateway = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="bp-treepg-banner">
      <WalletConnect isOpen={isOpen} setOpen={setOpen} />
      <button onClick={() => setOpen(true)} className="bp-treepg-btn">
        <img src={foximg} alt="foximg" />
        Connect MetaMask
      </button>

      {/*<Popup trigger={
						<button className="bp-treepg-btn">
						<img src={foximg} alt="foximg"/>Connect MetaMask
						</button>
					}modal nested>
				    {close => (
				      <div className="modal">
				        <button className="close" onClick={close}>
				          &times;
				        </button>
				        <div className="header">Join The Club</div>
				        <div className="content bp-treepg-pop">
				            <p>Become a Member<br/>This treehouse is for members only. To get a baby ape, you can get one from our collection on OpenSea.
							</p>
							<a href="https://opensea.io/collection/apevolution-club" target="_blank" rel="noreferrer" className="bp-treepg-popbtn">
								<img src={Logomark} alt="OpenSea"/>
								Buy on OpenSea
							</a>
				        </div>
				        
				      </div>
				    )}
				</Popup>*/}
    </section>
  );
};

export default TreehousePreGateway;
