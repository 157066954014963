import React from 'react';

// Style
import "./banner.css";
import discordimg from '../../assets/images/discordimg.png';
import { Container,Row, Col} from 'reactstrap';
const Banner = (props) => {
	return(
		<section className="bp-home-banner">
			<Container>
				<Row>
				    <Col md={8}>
				        <div className="bp-hoba-main">
							<h1>An Apevolution <br/>is Here</h1>
							<p>This is the start of a new era of Apes. Strange ape species are created as a result of a mad scientist ape’s obsession to save his own kind.</p>
							<ul className="bp-hoba-btn">
								<li><a href="https://medium.com/@babyapes/the-next-phase-of-baby-apes-rescue-66fc2b07365b" target="_blank" rel="noreferrer">View Whitepaper</a></li>
								<li><a href="https://discord.gg/M4MnHtnjFn" target="_blank" rel="noreferrer"><img src={discordimg} alt="Discord"/> Join Our Discord</a></li>
							</ul>
						</div>	
					</Col>
		  		</Row>
			</Container>
		</section>
		)
}

export default Banner;