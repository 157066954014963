import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { POLYGON_CHAIN_ID, POLYGON_HTTPS } from "./constants";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 4, 80001, 137],
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    [POLYGON_CHAIN_ID]: POLYGON_HTTPS,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const desktopWalletList = [
  {
    name: "Metamask",
    connector: injected,
    connectorType: InjectedConnector,
  },
  {
    name: "WalletConnect",
    connector: walletconnect,
    connectorType: WalletConnectConnector,
  },
];

export const walletList = desktopWalletList;
