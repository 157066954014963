import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

// Style
import { Container, Row, Col } from "reactstrap";
import { toEther } from "../../contracts/getContracts";
import { useNftContract } from "../../sms/hooks/nft-contract";
import { useTokenContract } from "../../sms/hooks/token-contract";
import "./style.css";
const TreehouseGallery = (props) => {
  const [displayTip, setTip] = useState(false);
  const [displayApe, setdisplayApe] = useState(false);

  const handleOpenTip = () => setTip(true);
  const handleCloseTip = () => setTip(false);

  const handleOpenApe = () => setdisplayApe(true);
  const handleCloseApe = () => setdisplayApe(false);

  const [state, setState] = useState({
    loading: true,
    name: null,
    random: Math.random(),
  });

  const {
    changeName,
    account,
    chainId,
    tipBabyApeHolder,
    getChangeNamePrice,
    changeNamePrice,
  } = useNftContract();
  const { allowance, approve } = useTokenContract();

  const handlerRandom = () => {
    setState({
      ...state,
      name: null,
      loading: true,
      random: Math.random(),
    });
  };

  useEffect(async () => {
    let isAllow = await allowance();
    if (isAllow) {
      setState({
        ...state,
        name: null,
        loading: false,
      });
    } else {
      setState({
        ...state,
        name: "Approve",
        loading: false,
      });
    }
  }, [state.random, account, chainId]);

  useEffect(() => {
    getChangeNamePrice();
  }, [state.random, account, chainId]);

  return (
    <section className="bp-tree-main">
      <Container>
        <Row>
          <Col md={12}>
            <h1>Treehouse Gallery</h1>
            <p>
              To display one of your baby apes in this Treehouse Gallery, you
              have to use 60 banana bar and give your baby ape a name.
            </p>
            <ul>
              <li>
                <button onClick={handleOpenApe} className="bp-tree-btn">
                  Display My Baby Ape
                </button>
                <Popup
                  // trigger={
                  // }
                  modal
                  nested
                  open={displayApe}
                  onClose={handleCloseApe}
                >
                  {/* {(close) => ( */}
                  <div className="modal">
                    <button className="close" onClick={handleCloseApe}>
                      &times;
                    </button>
                    <div className="header">Display Your Baby Ape</div>
                    <div className="content">
                      <p>
                        To display your baby ape on the treehouse, you have to
                        pay {toEther(changeNamePrice)} $BAR.
                      </p>

                      <form className="bp-mod-form-thg">
                        <div className="bp-mod-form">
                          <label>ID#</label>
                          <input type="number" name="id" id="nft-id-u" />
                        </div>
                        <div className="bp-mod-form">
                          <label>Name</label>
                          <input type="text" name="name" id="nft-name-u" />
                        </div>
                        <div className="bp-mod-form-sub">
                          <input
                            onClick={async (e) => {
                              e.preventDefault();

                              if (state.name == "Approve") {
                                await approve();
                                handlerRandom();
                              } else {
                                let id =
                                  document.getElementById("nft-id-u").value;
                                let name =
                                  document.getElementById("nft-name-u").value;

                                await changeName(id, name);
                                handleCloseApe();
                                // setTimeout(() => {
                                //   refetch();
                                // }, 2000);
                              }
                            }}
                            type="submit"
                            value={state.name ? state.name : "Confirm"}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* // )} */}
                </Popup>
              </li>
              <li>
                <button onClick={handleOpenTip} className="bp-tree-btn">
                  Tip a Baby Ape
                </button>
                <Popup onClose={handleCloseTip} modal nested open={displayTip}>
                  {/* {(close) => ( */}
                  <div className="modal">
                    <button className="close" onClick={handleCloseTip}>
                      &times;
                    </button>
                    <div className="header">Tip a Baby Ape</div>
                    <div className="content">
                      <p>
                        To display your baby ape on the treehouse, you have to
                        pay 60 $BAR.
                      </p>
                      <form className="bp-mod-form-thg">
                        <div className="bp-mod-form">
                          <label>ID#</label>
                          <input type="number" name="id" id="nft-id-tip" />
                        </div>
                        <div className="bp-mod-form">
                          <label>$BAR AMOUNT</label>
                          <input
                            type="text"
                            name="amount"
                            id="nft-id-tip-amount"
                          />
                        </div>
                        <div className="bp-mod-form-sub">
                          <input
                            disabled={state.loading}
                            onClick={async (e) => {
                              e.preventDefault();
                              if (state.name == "Approve") {
                                await approve();
                                handlerRandom();
                              } else {
                                let id =
                                  document.getElementById("nft-id-tip").value;
                                let name =
                                  document.getElementById(
                                    "nft-id-tip-amount"
                                  ).value;

                                await tipBabyApeHolder(id, name);
                                handleCloseTip();
                              }
                            }}
                            type="submit"
                            value={state.name ? state.name : "Confirm"}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* )} */}
                </Popup>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TreehouseGallery;
