import React from 'react';

// video
import video from '../../assets/video/TreehouseExt.mp4';

// Style
import "./style.css";
const VideoBanner = (props) => {
	return(
		<section>
		<div className="bp-home-vidbanner">
			<video width="" height="" loop autoPlay muted>
			  <source src={video} type="video/mp4"/>
			</video>
			<a href="/insidethetreehouse" className="treehouse_btn">Enter Treehouse</a>
		</div>
		</section>
		)
}

export default VideoBanner;