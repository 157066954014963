import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { HEROKU_NFT, SUBGRAPH_URI_POLYGON } from "../../utils/constants";

const query = `{
   nameEs {
     id
     name
   }
 }
 `;

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: SUBGRAPH_URI_POLYGON,
  }),
  // refetchOnMountOrArgChange: 5,
  endpoints: (build) => ({
    getNames: build.query({
      query: () => ({
        method: "POST",
        body: JSON.stringify({ query }),
        // keepalive: true,
      }),
      // keepUnusedDataFor: 10,
      transformResponse: async ({ data: { nameEs } }) => {
        let ids = nameEs.map((name) => name?.id);

        let metas = await fetch(`${HEROKU_NFT}[${ids.join(",")}]`);
        // Promise.all([
        //   fetch(`${HEROKU_NFT}[${ids.join(",")}]`),
        // ]);

        let metasJSON = await metas.json(); //Promise.all(metas.map((meta) => meta.json()));

        return metasJSON.map((meta, idx) => {
          return {
            ...nameEs[idx],
            image: meta?.image,
          };
        });
      },
    }),
  }),
});

export const { useGetNamesQuery } = apiSlice;
