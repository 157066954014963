import React from 'react';

// Style
import "./style.css";
const MovingBanner = (props) => {
	return(
		<section className="bp-home-movingbanner">
			<div className="bp-home-mbm">
			</div>
		</section>
		)
}

export default MovingBanner;