import React from 'react';
import Popup from 'reactjs-popup';

// Images
import foximg from '../../assets/images/metamask-fox 1.png';
import Logomark from '../../assets/images/Logomark-Blue 1.png';
import { Container,Row, Col} from 'reactstrap';
// Style
import "./style.css";
const TreehousePreGatewayNonMember = (props) => {
	return(
		<section className="bp-treepg-banner">
		<Container>
				
			<Row className="align-centre">
	        	<Col md={12}>
				<div className="bp-fadi-box align-centre bp-thpgnm-tew">
		        
		        <div className="header">Join The Club</div>
		        <div className="content bp-treepg-pop ">
		            <p>Become a Member<br/>This treehouse is for members only. To get a baby ape, you can get one from our collection on OpenSea.
					</p>
					<a href="https://opensea.io/collection/apevolution-club" target="_blank" rel="noreferrer" className="bp-treepg-popbtn">
						<img src={Logomark} alt="OpenSea"/>
						Buy on OpenSea
					</a>
		        </div>
		        
		      </div>
            </Col>
		  </Row>  
		  </Container>
		</section>
		
		)
}

export default TreehousePreGatewayNonMember;


