import { useDispatch, useSelector } from "react-redux";
import {
  bananaBarContract,
  switchNetwork,
  toEther,
} from "../../contracts/getContracts";
import { useCustomWeb3React } from "../../hooks/useCustomWeb3React";
import {
  CONTRACT_ADDRESSES,
  ETHEREUM_DATA,
  MAX_UINT256,
  POLYGON_CHAIN_ID,
} from "../../utils/constants";
import {
  setBalance,
  setTotalSupply,
  setWalletBalance,
} from "../slices/token-contract";

export const useTokenContract = () => {
  const { account, chainId } = useCustomWeb3React();
  const contract = bananaBarContract();
  const dispatch = useDispatch();

  const tokenContractState = useSelector((state) => state.token);

  const polygonChain = (_switch = false) => {
    if (!contract || !account) return;
    if (parseInt(chainId) !== POLYGON_CHAIN_ID) {
      _switch &&
        switchNetwork(ETHEREUM_DATA, account, parseInt(POLYGON_CHAIN_ID));
      return;
    }
    return true;
  };

  const loadRewards = async () => {
    // dispatch(
    //   setWalletBalance({
    //     totalClaimable: 0,
    //     generatedByOG: 0,
    //     generatedByCloneAndMad: 0,
    //     generatedByMonster: 0,
    //   })
    // );

    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain()) return;

    try {
      const totalClaimable = parseFloat(
        toEther(await contract.methods.getTotalClaimable(account).call())
      ).toFixed(2);
      const generatedByOG = parseFloat(
        toEther(await contract.methods.getTotalOGClaimable(account).call())
      ).toFixed(2);
      const generatedByCloneAndMad = parseFloat(
        toEther(await contract.methods.getTotalCloneClaimable(account).call())
      ).toFixed(2);
      const generatedByMonster = parseFloat(
        toEther(await contract.methods.getTotalStolenClaimable(account).call())
      ).toFixed(2);

      dispatch(
        setWalletBalance({
          totalClaimable,
          generatedByOG,
          generatedByCloneAndMad,
          generatedByMonster,
        })
      );
    } catch {}
  };

  const totalSupplyF = async () => {
    // dispatch(setTotalSupply(0));
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain()) return;

    try {
      let totalSuppl = parseFloat(
        toEther(await contract.methods.totalSupply().call())
      ).toFixed(2);
      dispatch(setTotalSupply(totalSuppl));
    } catch {}
  };

  const balanceOf = async () => {
    // dispatch(setBalance(0));
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain()) return;
    try {
      let balance = parseFloat(
        toEther(await contract.methods.balanceOf(account).call())
      ).toFixed(2);
      dispatch(setBalance(balance));
    } catch {}
  };

  const claimRewards = async () => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods.claimReward().send({
        from: account,
      });
      await loadRewards();
    } catch {}
  };

  const approve = async () => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods
        .approve(CONTRACT_ADDRESSES.babyApeNFT, MAX_UINT256)
        .send({
          from: account,
        });
    } catch {}
  };

  const allowance = async () => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain()) return;

    try {
      let all = await contract.methods
        .allowance(account, CONTRACT_ADDRESSES.babyApeNFT)
        .call();
      return parseFloat(all) > 0;
    } catch {}
  };

  return {
    loadRewards,
    claimRewards,
    balanceOf,
    approve,
    allowance,
    totalSupplyF,
    ...tokenContractState,
  };
};
