import React from 'react';

// Style
import "./apevolutioncollection.css";
import discordimg from '../../assets/images/discordimg.png';
import { Container,Row, Col} from 'reactstrap';
const ApevolutionClubCollection = (props) => {
	return(
		<section className="apevolution-sec">
			<Container>
				<Row>
				    <Col md={7}>
				        <div className="apevolution-cont">
							<h2>What’s Coming in the Apevolution Club Collection?</h2>
							<ul className="apevolution-btn">
								<li><a href="https://medium.com/@babyapes/the-next-phase-of-baby-apes-rescue-66fc2b07365b" target="_blank" rel="noreferrer">View Whitepaper</a></li>
								<li><a href="https://discord.gg/M4MnHtnjFn" target="_blank" rel="noreferrer"><img src={discordimg} alt="Discord"/> Join Our Discord</a></li>
							</ul>
						</div>	
					</Col>
		  		</Row>
			</Container>
		</section>
		)
}

export default ApevolutionClubCollection;