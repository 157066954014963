import React, { useEffect, useState } from "react";

// Components
import Layout from "../../components/Layout/Layout";

import TreehousePreGatewayNonMember from "../../components/TreehousePreGatewayNonMember/TreehousePreGatewayNonMember";

const Migration = () => {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  // if (loading) {
  //   return <>Loading...</>;
  // }
  return (
    <Layout>
      <TreehousePreGatewayNonMember />
    </Layout>
  );
};

export default Migration;
