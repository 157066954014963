import React from "react";

// Images
import BananaBAR from "../../assets/images/banana-bar.png";
import bar from "../../assets/images/barsymbolitth.png";
import greenbann from "../../assets/images/greenbann.png";
import { useTokenContract } from "../../sms/hooks/token-contract";
// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";

const BananaBARSmartContract = (props) => {
  const {
    claimRewards,
    balance,

    walletBalance: {
      totalClaimable,
      generatedByOG,
      generatedByCloneAndMad,
      generatedByMonster,
    },
  } = useTokenContract();

  return (
    <section className="bp-bnbarsc">
      <Container>
        <Row className="justify-content-center">
          <Col md={7} className="banana-center-box">
            <div className="bp-itth-inbx banana-bar-box">
              <div className="bp-itth-inbx-head">
                <h2>
                  Your Wallet Balance
                  <span>
                    <img src={bar} alt="bar" />
                    {balance}
                  </span>
                </h2>
              </div>
              <div className="bp-itth-inbx-cont">
                <ul>
                  <li>
                    <h4>Total Available to Claim</h4>
                    <h3>
                      {
                        parseFloat(totalClaimable).toFixed(2)
                        // +
                        //   parseFloat(generatedByOG) +
                        //   parseFloat(generatedByCloneAndMad) +
                        //   parseFloat(generatedByMonster)
                      }{" "}
                      $BAR
                    </h3>
                  </li>
                </ul>
              </div>

              <div className="bp-itth-inbx-btn">
                <a
                  href="#."
                  onClick={claimRewards}
                  className="bp-treepg-popbtn-modal"
                >
                  <img src={greenbann} alt="icon" />
                  Claim $BAR
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BananaBARSmartContract;
