import { useState, useEffect } from "react";
// import { AbstractConnector } from "@web3-react/abstract-connector";

import { setWeb3Provider } from "../contracts/getContracts";
import { injected } from "../utils/connectors";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useCustomWeb3React } from "./useCustomWeb3React";

export function useEagerConnect() {
  const { activate, active, account } = useCustomWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      let status = localStorage.getItem("status");
      if (isAuthorized && status !== "disconnected") {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [account]);

  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export const useActivateWallet = () => {
  const { activate, library, account } = useCustomWeb3React();

  useEffect(() => {
    if (library) {
      setWeb3Provider(library);
    }
  }, [library, account]);

  return async (connector, onClose = () => {}) => {
    try {
      if (
        connector instanceof WalletConnectConnector &&
        connector.walletConnectProvider?.wc?.uri
      ) {
        connector.walletConnectProvider = undefined;
      }
      await activate(connector ? connector : injected, undefined, true);
      onClose(true, undefined);
    } catch (e) {
      onClose(undefined, e);
      console.log("Wallet Error => crap ", e);
    }
  };
};
