import React from 'react';

// Images
import BananaBAR from '../../assets/images/banana-bar.png';

// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const BananaBARBanner = (props) => {
	return(
		<section className="bp-bnbar-main">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<img src={BananaBAR} alt="BananaBAR"/>
		    	<h1>Banana $BAR</h1>
		    	<p>At Apevolution Club, our goal is to constantly ape forward and keep pushing to the next level. Through the launch of Banana $BAR, it will bring utility to our current collection and this token can be used throughout the Baby Apevolution ecosystem. When $BAR is implemented, we will continue to expand future uses for our utility token.</p>
				<p>Our vision goes beyond just being a PFP project and harness the power of the blockchain. We want to keep doing cool stuff for our community!</p>
		    </Col>
		  </Row>
		 </Container>
		</section>
		
		)
}

export default BananaBARBanner;


