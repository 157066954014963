import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Style
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
//Pages
import Home from "./pages/Home/Home";
import Migration from "./pages/Migration/Migration";
import Treehouse from "./pages/Treehouse/Treehouse";
import CloningLaboratory from "./pages/CloningLaboratory/CloningLaboratory";
import TermsOfServices from "./pages/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TreehousePreGateway from "./pages/TreehousePreGateway/TreehousePreGateway";
import TreehousePreGatewayNonMember from "./pages/TreehousePreGatewayNonMember/TreehousePreGatewayNonMember";
import InsidetheTreehouse from "./pages/InsidetheTreehouse/InsidetheTreehouse";
import NFTPage from "./pages/NFTPage/NFTPage";
import BananaBAR from "./pages/BananaBAR/BananaBAR";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useToaster } from "./hooks/toaster";
import { useTokenContract } from "./sms/hooks/token-contract";
import { useNftContract } from "./sms/hooks/nft-contract";
import { babyApeContractWSS } from "./contracts/getContracts";
import { useCustomWeb3React } from "./hooks/useCustomWeb3React";

function App() {
  const {} = useToaster();
  const [loading, setLoading] = useState(true);
  const [wait, setWait] = useState(true);
  const [join, setJoin] = useState(false);
  const { account, chainId, active } = useCustomWeb3React();
  const { loadRewards, walletBalance, balanceOf, totalSupplyF } =
    useTokenContract();

  const {
    loadUserNfts,
    userNfts,
    // getOldUserNfts,
    addEventInfo,
    loadGameStatus,
    updateMigrate,
    isNftsExist,
    getMaticMintCount,
    getRevealFee,
  } = useNftContract();

  let isMember = join ? (
    <TreehousePreGatewayNonMember />
  ) : (
    <InsidetheTreehouse />
  );
  let isConnected = account ? isMember : <TreehousePreGateway />;

  // console.log("walletBalance", walletBalance);
  // console.log("userNfts", userNfts);

  useEffect(() => {
    loadRewards();
    loadUserNfts();
    balanceOf();
    // getOldUserNfts();
    totalSupplyF();
    loadGameStatus();
    updateMigrate();
    getMaticMintCount();
    getRevealFee();
  }, [account, chainId]);

  useEffect(() => {
    let it = setInterval(() => {
      totalSupplyF();
      balanceOf();
      loadRewards();

      updateMigrate();
      loadGameStatus();
      loadUserNfts();
      getMaticMintCount();
      // heroku request
      // getOldUserNfts();
    }, 30000);
    return () => {
      clearInterval(it);
    };
  }, [account, chainId]);

  useEffect(() => {
    let contract = babyApeContractWSS();
    let subscribe = contract?.events
      .ApevolutionMinted({})
      .on("data", (event) => {
        // setTimeout(() => {
        //   console.log(event.returnValues);
        const { isSteal, totalMinted, minter } = event.returnValues;
        //   console.log(isSteal, totalMinted, minter);
        //   let m = new String(minter);
        //   if (m && account.toLowerCase() == m.toLowerCase()) {
        addEventInfo(isSteal, totalMinted, minter);
        //   }
        // }, 1000);
      });

    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  useEffect(async () => {
    setLoading(true);
    if (account) {
      let bool = await isNftsExist();
      setJoin(!bool);
    } else {
      setJoin(false);
    }
    setLoading(false);
  }, [account, chainId]);

  // useEffect(()=>{
  //   setTimeout(() => {
  //       document.getElementsByTagName("iframe")[0].remove();
  //     }, 100);
  // },[window.location])
  useEffect(() => {
    // setTimeout(() => {
    //   setWait(false);
    // }, 1000);
  }, []);

  // if (loading) {
  //   return <>Loading...</>;
  // }

  return (
    <div className="App">
      <ToastContainer />

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/migration" element={<Migration />} />
          <Route path="/treehouse-gallery" element={<Treehouse />} />
          {<Route path="/cloning-laboratory" element={<CloningLaboratory />} />}
          <Route path="/terms-of-service" element={<TermsOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route
            path="/treehousepregateway"
            element={<TreehousePreGateway />}
          /> */}
          {/* <Route
            path="/treehousepregateway-new"
            element={}
          /> */}

          <Route path="/insidethetreehouse" element={isConnected} />
          <Route path="/nftpage" element={<NFTPage />} />
          <Route path="/bananabar" element={<BananaBAR />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
