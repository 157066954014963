import React from 'react';

// Images
import yahoo from '../../assets/images/yahoo.png';
import market_watch from '../../assets/images/market_watch.png';
import bitboy from '../../assets/images/bitboy.png';

// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const FeaturedOn = (props) => {
	return(
		<section className="bp-home-fo">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<h2>Previously Featured On</h2>
		    </Col>
		    <Col md={12}>
		    	<ul className="featured_images">
		    		<li><a href="https://finance.yahoo.com/news/highly-anticipated-nft-project-baby-130000847.html" target="_blank" rel="noreferrer"><img src={yahoo} alt="yahoo"/> </a></li>
		    		<li><a href="https://www.marketwatch.com/press-release/highly-anticipated-nft-project-baby-apes-rescue-to-launch-later-this-week-2021-10-14" target="_blank" rel="noreferrer"><img src={market_watch} alt="market_watch"/> </a></li>
		    		<li><a href="https://www.youtube.com/watch?v=4yrC7GlbHas" target="_blank" rel="noreferrer"><img src={bitboy} alt="bitboy"/> </a></li>
		    	</ul>
		    </Col>
		  </Row>
		 </Container>
		</section>
		)
}

export default FeaturedOn;