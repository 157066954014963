import React from 'react';

// Components
import Layout from "../../components/Layout/Layout";

import Migrationcontent from "../../components/Migration/Migrationcontent";
import MigrationStep from "../../components/MigrationStep/MigrationStep";



const Migration = () => {
  return (
  	<Layout>
  		<Migrationcontent/>
  		<MigrationStep/>
  	</Layout>
	) 
}

export default Migration;