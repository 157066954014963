import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletBalance: {
    totalClaimable: 0,
    generatedByOG: 0,
    generatedByCloneAndMad: 0,
    generatedByMonster: 0,
  },
  balance: 0,
  totalSupply: 0,
};

const tokenContract = createSlice({
  name: "tokenContract",
  initialState,
  reducers: {
    setWalletBalance(state, { payload, type }) {
      state.walletBalance = payload;
    },
    setBalance(state, { payload, type }) {
      state.balance = payload;
    },
    setTotalSupply(state, { payload, type }) {
      state.totalSupply = payload;
    },
  },
});

export const { setWalletBalance, setBalance, setTotalSupply } =
  tokenContract.actions;
export default tokenContract.reducer;
