import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

// Images
import apeyb from "../../assets/images/apeyb.png";
import bar from "../../assets/images/barsymbol.png";

// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useNftContract } from "../../sms/hooks/nft-contract";
import { useTokenContract } from "../../sms/hooks/token-contract";
import { toEther } from "../../contracts/getContracts";
const NFTPage = (props) => {
  const [displayName, setDisplayName] = useState(false);
  const [displayBio, setDisplayBio] = useState(false);

  const [displayBirthdate, setDisplayBirthdate] = useState(false);
  const [displayClone, setDisplayClone] = useState(false);

  const handleOpenDisplayName = () => setDisplayName(true);
  const handleCloseDisplayName = () => setDisplayName(false);

  const handleOpenDisplayBio = () => setDisplayBio(true);
  const handleCloseDisplayBio = () => setDisplayBio(false);

  const handleOpenDisplayBirthdate = () => setDisplayBirthdate(true);
  const handleCloseDisplayBirthdate = () => setDisplayBirthdate(false);

  const handleOpenDisplayClone = () => setDisplayClone(true);
  const handleCloseDisplayClone = () => setDisplayClone(false);

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { description, image, name, attributes, tokenId } = locationState || {};

  if (!description || !image || !attributes || !tokenId || !locationState) {
    navigate(-1);
  }

  // console.log(props, state);
  const convertFormatedDate = (timestamp) => {
    return `${new Date(timestamp).getMonth() + 1}-${new Date(
      timestamp
    ).getDate()}-${new Date(timestamp).getFullYear()}`;
  };

  const type = attributes?.find((x) => x.trait_type === "Type")?.value;

  let birthdate = attributes?.find((x) => x.trait_type === "birthday")?.value;

  let formatedBirthdate =
    birthdate === 0 ? "0-0-0" : convertFormatedDate(birthdate * 1000);

  const [state, setState] = useState({
    loading: true,
    name: null,
    random: Math.random(),
  });

  const {
    changeName,
    changeBio,
    changeBirthdate,
    account,
    chainId,
    mintNFT,
    baseBarMintFee,
    discountEligibility,
    discountTxLimit,
    discountTxCount,
    getDiscountAvailTxCount,
    getDiscountEligiblity,
    getDiscountTxLimit,
    barMintFeeBase,
    getChangeNamePrice,
    getChangeBirthdatePrice,
    getChangeBioPrice,
    changeNamePrice,
    changeBioPrice,
    changeBirthdatePrice,
  } = useNftContract();

  const { allowance, approve } = useTokenContract();

  const handlerRandom = () => {
    setState({
      ...state,
      name: null,
      loading: true,
      random: Math.random(),
    });
  };

  useEffect(async () => {
    let isAllow = await allowance();
    if (isAllow) {
      setState({
        ...state,
        name: null,
        loading: false,
      });
    } else {
      setState({
        ...state,
        name: "Approve",
        loading: false,
      });
    }
  }, [state.random, account, chainId]);

  useEffect(() => {
    getDiscountAvailTxCount();
    getDiscountEligiblity();
    getDiscountTxLimit();
    barMintFeeBase();
    getChangeNamePrice();
    getChangeBirthdatePrice();
    getChangeBioPrice();
  }, [state.random, account, chainId]);

  const ELIGIBILITY =
    discountEligibility &&
    parseInt(discountTxCount) < parseInt(discountTxLimit);
  return (
    <section className="bp-ntfp-main">
      <Container>
        <Row>
          <Col md={4}>
            <div className="bp-ntfp-ape">
              <img src={image} alt="ape" />
            </div>
          </Col>
          <Col md={7}>
            <div className="bp-ntfp-cont">
              <ul className="bp-ntfp-det">
                <li>
                  <h4>Name</h4>
                  <h2>{name}</h2>
                </li>
                <li>
                  <h4>Birthdate</h4>
                  <h2>{formatedBirthdate}</h2>
                </li>
                <li>
                  <h4>Token ID</h4>
                  <h2>#{tokenId}</h2>
                </li>
                <li>
                  <h4>Type</h4>
                  <h2>{type}</h2>
                </li>
              </ul>

              <h4>Bio</h4>
              <p>{description}</p>

              <ul className="bp-ntfp-pop">
                <li>
                  <button
                    onClick={handleOpenDisplayName}
                    className="bp-ntfp-btn"
                  >
                    Change Name
                  </button>
                  <Popup
                    // trigger={
                    // }
                    modal
                    nested
                    open={displayName}
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          onClick={handleCloseDisplayName}
                        >
                          &times;
                        </button>
                        <div className="header">Change Name</div>
                        <div className="content">
                          <form className="bp-ntfp-form-cn">
                            <div className="bp-ntfp-form">
                              <input
                                type="text"
                                name="name"
                                id="nft-page-name"
                                placeholder="Type new name here..."
                              />
                            </div>

                            <div className="bp-ntfp-form-sub">
                              <label>
                                Cost <img src={bar} alt="bar" />
                                <span>{toEther(changeNamePrice)}</span>
                              </label>
                              <input
                                onClick={async (e) => {
                                  e.preventDefault();

                                  if (state.name == "Approve") {
                                    await approve();
                                    handlerRandom();
                                  } else {
                                    await changeName(
                                      tokenId,
                                      document.getElementById("nft-page-name")
                                        .value
                                    );
                                    handleCloseDisplayName();
                                  }
                                }}
                                type="submit"
                                value={state.name ? state.name : "Confirm"}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
                <li>
                  <button
                    onClick={handleOpenDisplayBio}
                    className="bp-ntfp-btn"
                  >
                    Change Bio
                  </button>
                  <Popup
                    // trigger={
                    // }
                    modal
                    nested
                    open={displayBio}
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          onClick={handleCloseDisplayBio}
                        >
                          &times;
                        </button>
                        <div className="header">Change Bio</div>
                        <div className="content">
                          <form className="bp-ntfp-form-cn">
                            <div className="bp-ntfp-form">
                              <textarea
                                placeholder="Type new Bio here..."
                                name="bio"
                                id="nft-page-bio"
                              ></textarea>
                            </div>

                            <div className="bp-ntfp-form-sub">
                              <label>
                                Cost <img src={bar} alt="bar" />
                                <span>{toEther(changeBioPrice)}</span>
                              </label>
                              <input
                                onClick={async (e) => {
                                  e.preventDefault();

                                  if (state.name == "Approve") {
                                    await approve();
                                    handlerRandom();
                                  } else {
                                    await changeBio(
                                      tokenId,
                                      document.getElementById("nft-page-bio")
                                        .value
                                    );
                                    handleCloseDisplayBio();
                                  }
                                }}
                                type="submit"
                                value={state.name ? state.name : "Confirm"}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
                <li>
                  <button
                    onClick={handleOpenDisplayClone}
                    className="bp-ntfp-btn"
                  >
                    Clone
                  </button>
                  <Popup modal nested open={displayClone}>
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          onClick={handleCloseDisplayClone}
                        >
                          &times;
                        </button>
                        <div className="header">Clone Confirmation</div>
                        <div className="content">
                          <form className="bp-ntfp-form-cn">
                            <div className="bp-ntfp-form-c">
                              <label>
                                Cost <img src={bar} alt="bar" />
                                <span>
                                  {parseInt(toEther(baseBarMintFee)) -
                                    (ELIGIBILITY
                                      ? parseInt(toEther(baseBarMintFee)) * 0.1
                                      : 0)}{" "}
                                  {ELIGIBILITY && " (10% Lower)"}
                                </span>
                              </label>
                            </div>

                            <div className="bp-ntfp-formc-sub">
                              <input
                                onClick={async (e) => {
                                  e.preventDefault();

                                  if (state.name == "Approve") {
                                    await approve();
                                    handlerRandom();
                                  } else {
                                    await mintNFT(undefined);
                                    handleCloseDisplayClone();
                                  }
                                }}
                                type="submit"
                                value={state.name ? state.name : "Confirm"}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
                <li>
                  <button
                    onClick={handleOpenDisplayBirthdate}
                    className="bp-ntfp-btn"
                  >
                    Change Birthdate
                  </button>
                  <Popup
                    // trigger={
                    // }
                    modal
                    nested
                    open={displayBirthdate}
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          onClick={handleCloseDisplayBirthdate}
                        >
                          &times;
                        </button>
                        <div className="header">Change Birthdate</div>
                        <div className="content">
                          <form className="bp-ntfp-form-cn">
                            <div className="bp-ntfp-form bp-ntfp-form-dob">
                              <input
                                type="date"
                                name="dob"
                                id="dob-nft"
                                min="today"
                                placeholder="DD/MM/YY"
                              />
                            </div>

                            <div className="bp-ntfp-form-sub">
                              <label>
                                Cost <img src={bar} alt="bar" />
                                <span>{toEther(changeBirthdatePrice)}</span>
                              </label>
                              <input
                                onClick={async (e) => {
                                  e.preventDefault();

                                  if (state.name == "Approve") {
                                    await approve();
                                    handlerRandom();
                                  } else {
                                    let date =
                                      new Date(
                                        document.getElementById("dob-nft").value
                                      ).getTime() / 1000;

                                    await changeBirthdate(tokenId, date);
                                    handleCloseDisplayBirthdate();
                                  }
                                }}
                                type="submit"
                                value={state.name ? state.name : "Confirm"}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NFTPage;
