import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

// Images
import cloningimg from "../../assets/images/barsymbol.png";
import plus from "../../assets/images/plus.png";
import minus from "../../assets/images/minus.png";
import eicons_loading from "../../assets/images/eos-icons_loading.png";
import apeyellow from "../../assets/images/apeyellow.png";
// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import { useNftContract } from "../../sms/hooks/nft-contract";
import { useTokenContract } from "../../sms/hooks/token-contract";
import { toEther } from "../../contracts/getContracts";
const CloningLaboratoryProcess = (props) => {
  const [stolen, setStolen] = useState([]);
  const [notStolen, setNotStolen] = useState([]);
  const [popup, setPopup] = useState(false);
  const [quantitycl, setQuantitycl] = useState(1);
  const [quantitycln, setQuantitycln] = useState(1);
  const [quantityclm, setQuantityclm] = useState(1);

  const [state, setState] = useState({
    loading: true,
    name: null,
    random: Math.random(),
  });

  const {
    mintNFT,
    loading,
    clearLoading,
    userNfts,
    event,
    account,
    chainId,
    maticMintPrice,
    getDiscountAvailTxCount,
    getDiscountEligiblity,
    getDiscountTxLimit,
    barMintFeeBase,
    baseBarMintFee,
    discountEligibility,
    discountTxLimit,
    discountTxCount,
    getTotalBabeApeMinted,
    totalBabeApeMinted,
    maticMintCount,
  } = useNftContract();

  const { allowance, approve } = useTokenContract();

  const handlerRandom = () => {
    setState({
      ...state,
      name: null,
      loading: true,
      random: Math.random(),
    });
  };

  useEffect(async () => {
    let isAllow = await allowance();
    if (isAllow) {
      setState({
        ...state,
        name: null,
        loading: false,
      });
    } else {
      setState({
        ...state,
        name: "Approve",
        loading: false,
      });
    }
  }, [state.random, account, chainId]);

  useEffect(() => {
    getDiscountAvailTxCount();
    getDiscountEligiblity();
    getDiscountTxLimit();
    barMintFeeBase();
    getTotalBabeApeMinted();
  }, [state.random, account, chainId]);

  const MIN = 1;
  const MAX = 10;

  const incrementcl = () => {
    quantitycl + 1 <= MAX && setQuantitycl(quantitycl + 1);
  };

  const decrementcl = () => {
    quantitycl - 1 >= MIN && setQuantitycl(quantitycl - 1);
  };

  const incrementcln = () => {
    quantitycln + 1 <= MAX && setQuantitycln(quantitycln + 1);
  };

  const decrementcln = () => {
    quantitycln - 1 >= MIN && setQuantitycln(quantitycln - 1);
  };

  const incrementclm = () => {
    quantityclm + 1 <= MAX && setQuantityclm(quantityclm + 1);
  };

  const decrementclm = () => {
    quantityclm - 1 >= MIN && setQuantityclm(quantityclm - 1);
  };

  const handlePopup = () => {
    // isSteal,
    // tokenId,
    // minter
  };

  useEffect(() => {
    let _stolen = [];

    let _notStolen = [];
    event?.forEach((e) => {
      if (e?.isSteal && e?.minter.toLowerCase() == account.toLowerCase()) {
        _stolen.push(e?.tokenId);
      }
      if (e?.minter.toLowerCase() == account.toLowerCase()) {
        _notStolen.push(e?.tokenId);
      }
    });

    if (_stolen.length > 0 || _notStolen.length > 0) {
      setNotStolen(_notStolen);
      setStolen(_stolen);
      setPopup(true);
    }
  }, [event]);

  const ELIGIBILITY =
    discountEligibility &&
    parseInt(discountTxCount) < parseInt(discountTxLimit);

  return (
    <section className="bp-cltaproc-main">
      <Container>
        <Row>
          <Col md={4}>
            <div className="bp-cltaproc-inner">
              <h2>Clone with BAR</h2>
              <h3>{totalBabeApeMinted}/25,000 Minted</h3>
              <h3>
                Current Price:
                <img src={cloningimg} alt="cloninglaboratory" />{" "}
                {toEther((parseInt(baseBarMintFee) * quantitycl).toString())}
              </h3>
              <p>You have 10% chance of getting a Monster</p>
              <form className="bp-cltaproc-form">
                <div className="bp-cltaproc-form-inner">
                  <div className="bp-cltaproc-label">
                    <label>Quantity</label>
                  </div>
                  <div className="bp-cltaproc-input">
                    <span id="plus" onClick={incrementcl}>
                      <img src={plus} alt="plus" />
                    </span>
                    <input
                      type="number"
                      name="quantitycl"
                      id="quantitycl"
                      value={quantitycl}
                      pattern="[0-9]*"
                    />
                    <span id="minus" onClick={decrementcl}>
                      <img src={minus} alt="minus" />
                    </span>
                  </div>
                </div>
                <div className="bp-cltaproc-sub">
                  <input
                    onClick={async (e) => {
                      e.preventDefault();

                      if (state.name == "Approve") {
                        await approve();
                        handlerRandom();
                      } else {
                        await mintNFT(undefined, quantitycl);
                        handlerRandom();
                        handlePopup();
                      }
                    }}
                    type="submit"
                    value={state.name ? state.name : "Clone Now"}
                  />
                </div>
              </form>
            </div>
          </Col>
          <Col md={4}>
            <div className="bp-cltaproc-inner">
              <h2>Clone with Baby Ape OG</h2>
              <h3>{totalBabeApeMinted}/25,000 Minted</h3>

              <form className="bp-cltaproc-form bp-tokform">
                <div className="bp-cltaproc-id">
                  <label>Select Token Id:</label>
                </div>
                <div className="bp-cltaproc-select">
                  <select name="tokenid" id="tokenid">
                    <option value="">Please Select</option>
                    {userNfts &&
                      userNfts.map((id) =>
                        parseInt(id) < 2501 ? (
                          <option key={id} value={id}>
                            {id}
                          </option>
                        ) : (
                          <></>
                        )
                      )}
                    {/* <option value="#231">#231</option>
                    <option value="#231">#231</option>
                    <option value="#231">#231</option> */}
                  </select>
                </div>
                <div className="bp-cltaproc-select-label">
                  <h3>
                    Cost:
                    <img src={cloningimg} alt="cloninglaboratory" />{" "}
                    {(
                      (parseInt(toEther(baseBarMintFee)) -
                        (ELIGIBILITY
                          ? parseInt(toEther(baseBarMintFee)) * 0.1
                          : 0)) *
                      quantitycln
                    ).toString()}{" "}
                    {ELIGIBILITY && <span>(10% Lower)</span>}
                  </h3>
                </div>
                <div className="bp-cltaproc-form-inner">
                  <div className="bp-cltaproc-label">
                    <label>Quantity</label>
                  </div>
                  <div className="bp-cltaproc-input">
                    <span id="plus" onClick={incrementcln}>
                      <img src={plus} alt="plus" />
                    </span>
                    <input
                      type="number"
                      name="quantitycln"
                      id="quantitycln"
                      step="1"
                      min="1"
                      pattern="[0-9]*"
                      value={quantitycln}
                    />
                    <span id="minus" onClick={decrementcln}>
                      <img src={minus} alt="minus" />
                    </span>
                  </div>
                </div>
                <div className="bp-cltaproc-sub">
                  <input
                    onClick={async (e) => {
                      e.preventDefault();

                      if (state.name == "Approve") {
                        await approve();
                        handlerRandom();
                      } else {
                        await mintNFT(undefined, quantitycln);
                        handlerRandom();
                        handlePopup();
                      }
                    }}
                    type="submit"
                    value={state.name ? state.name : "Clone Now"}
                  />
                </div>
              </form>
            </div>
          </Col>
          <Col md={4}>
            <div className="bp-cltaproc-inner">
              <h2>Clone with MATIC</h2>
              <h3>{maticMintCount}/5000 Minted</h3>
              <h3>
                Price:{" "}
                {toEther((parseInt(maticMintPrice) * quantityclm).toString())}{" "}
                MATIC
              </h3>
              <p>You have 10% chance of getting a Monster</p>
              <form className="bp-cltaproc-form">
                <div className="bp-cltaproc-form-inner">
                  <div className="bp-cltaproc-label">
                    <label>Quantity</label>
                  </div>
                  <div className="bp-cltaproc-input">
                    <span id="plus" onClick={incrementclm}>
                      <img src={plus} alt="plus" />
                    </span>
                    <input
                      type="number"
                      name="quantityclm"
                      id="quantityclm"
                      step="1"
                      min="1"
                      pattern="[0-9]*"
                      value={quantityclm}
                    />
                    <span id="minus" onClick={decrementclm}>
                      <img src={minus} alt="minus" />
                    </span>
                  </div>
                </div>
                <div className="bp-cltaproc-sub">
                  <input
                    onClick={async (e) => {
                      e.preventDefault();
                      await mintNFT("undefined", quantityclm);
                      handlerRandom();
                      handlePopup();
                    }}
                    type="submit"
                    value="Clone Now with MATIC"
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={12}>
            <Popup
              //   trigger={<button className="bp-tree-btn">Cloning…</button>}
              modal
              nested
              open={loading === "mint"}
            >
              {(close) => (
                <div className="modal">
                  <button className="close" onClick={close}>
                    &times;
                  </button>

                  <div className="cl-cpopup">
                    <div className="header">Cloning…</div>
                    <div className="content">
                      <p>Please CONFIRM your MetaMask transaction.</p>
                      <p>
                        Do NOT refresh the page after clicking the “confirm”
                        button.
                      </p>
                      <img
                        src={eicons_loading}
                        alt="loading"
                        className="rotate"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Popup>
            <Popup
              //   trigger={
              //     <button className="bp-tree-btn">Clone is Successful!</button>
              //   }
              open={popup}
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button
                    className="close"
                    onClick={() => {
                      clearLoading();
                      setPopup(false);
                    }}
                  >
                    &times;
                  </button>
                  <div className="cl-successpopup">
                    <div className="header">Was the Clone Successful?</div>
                    <div className="content">
                      <div className="cl-succ-inner">
                        <div className="cl-ape-img">
                          {/* <div className="cl-ape-img-frame">
                            <img src={apeyellow} alt="ape" className="" />
                          </div> */}
                        </div>
                        <div className="cl-succ-mess">
                          {/* <h5>#{}</h5> */}
                          <p>
                            {notStolen.length > 0 &&
                              "Successfully Cloned: " +
                                notStolen.map((id) => `#${id}`).join(", ")}
                            {stolen.length > 0 && <br />}
                            {stolen.length > 0 &&
                              "Clones Stolen by Monster Ape: " +
                                stolen.map((id) => `#${id}`).join(", ")}
                            <br />
                            <br />
                            You can go to the treehouse and see your clone
                            appear there!
                          </p>
                        </div>
                      </div>
                      <div className="cl-btn">
                        <button
                          onClick={() => {
                            clearLoading();
                            setPopup(false);
                          }}
                        >
                          Dismiss
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
            {/* <Popup
              // trigger={
              //   <button className="bp-tree-btn">Your clone is stolen!</button>
              // }
              open={event?.isEventTriggered && event?.isSteal}
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button className="close" onClick={clearLoading}>
                    &times;
                  </button>
                  <div className="cl-successpopup">
                    <div className="header">Clone is Successful!</div>
                    <div className="content">
                      <div className="cl-succ-inner">
                        <div className="cl-ape-img">
                          <div className="cl-ape-img-frame">
                            <img src={apeyellow} alt="ape" className="" />
                          </div>
                        </div>
                        <div className="cl-succ-mess">
                          <h5>#{event?.tokenId}</h5>
                          <p>
                            Oh no… Unfortunately, your clone was stolen by a
                            Baby Ape Monster.
                          </p>
                        </div>
                      </div>
                      <div className="cl-btn">
                        <button onClick={clearLoading}>Dismiss</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Popup> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CloningLaboratoryProcess;
