import { configureStore } from "@reduxjs/toolkit";
import tokenContract from "./slices/token-contract";
import nftContract from "./slices/nft-contract";
import { apiSlice } from "./slices/api";

export const store = configureStore({
  reducer: {
    token: tokenContract,
    nft: nftContract,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  },
});
