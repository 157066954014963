import Web3 from "web3";
// import { provider } from "web3-core";
import { useActivateWallet, useEagerConnect } from "../hooks/walletConnect";
import { walletList } from "../utils/connectors";
import { CONTRACT_ADDRESSES, EVENT_WSS } from "../utils/constants";

import BananaBar from "./abis/BananaBar.json";
import BabyApeNFT from "./abis/BabyApeNFT.json";
import BabyApeNFTOld from "./abis/BabyApeNFTOld.json";
// import { logError } from '../utils/logs';
// import { CONTRACT_ADDRESSES, CHAIN_ID } from '../config/Constants';

let web3;

try {
  web3 = new Web3(window?.web3?.currentProvider);
  // web3Infura = new Web3(infuraUrl);
} catch (e) {
  console.log("Connect Web3", e);
}

export const setWeb3Provider = (provider) => {
  web3 = new Web3(provider);
};

export const babyApeOldContract = () => {
  let contract;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(
        BabyApeNFTOld,
        CONTRACT_ADDRESSES.babyApeNFTOld
      );
    } else {
      // contract = new web3Infura.eth.Contract(bearABI, CONTRACT_ADDRESSES.bear);
      throw new Error("web3 not found");
    }
    return contract;
  } catch (e) {
    console.log("contract", e);
  }
};

export const babyApeContract = () => {
  let contract;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(
        BabyApeNFT,
        CONTRACT_ADDRESSES.babyApeNFT
      );
    } else {
      // contract = new web3Infura.eth.Contract(bearABI, CONTRACT_ADDRESSES.bear);
      throw new Error("web3 not found");
    }
    return contract;
  } catch (e) {
    console.log("contract", e);
  }
};

export const babyApeContractWSS = () => {
  try {
    const instance = new Web3(EVENT_WSS);

    let contract = new instance.eth.Contract(
      BabyApeNFT,
      CONTRACT_ADDRESSES.babyApeNFT
    );

    return contract;
  } catch (e) {
    console.log("socket", e);
  }
};

export const bananaBarContract = () => {
  let contract;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(
        BananaBar,
        CONTRACT_ADDRESSES.bananaToken
      );
    } else {
      // contract = new web3Infura.eth.Contract(bearABI, CONTRACT_ADDRESSES.bear);
      throw new Error("web3 not found");
    }
    return contract;
  } catch (e) {
    console.log("contract", e);
  }
};

// export const mintNFT = async (account, value, count = 1, cb = () => {}) => {
//   try {
//     let contract = bearContract();
//     await contract.methods.mint(count).send({ from: account, value: value });
//     cb(true, undefined);
//   } catch (e) {
//     // console.log("mint", e);
//     cb(undefined, e);
//   }
// };

export const getBalance = async (account) => {
  try {
    return await web3.eth.getBalance(account);
  } catch (e) {
    console.log("balance", e);
  }
};

// export const getDutchAuctionForPublicMint = async () => {
//   try {
//     let contract = bearContract();
//     return await contract.methods.dutchAuctionForPublicMint().call();
//   } catch (e) {
//     console.log("mint", e);
//     return "0";
//   }
// };

export const toEther = (val) => {
  try {
    return web3.utils.fromWei(val, "ether");
  } catch {
    return "0";
  }
};
export const toWei = (val) => {
  try {
    return Web3.utils.toWei(val, "ether");
  } catch {
    return "0";
  }
};

export const useConnectWallet = () => {
  const activate = useActivateWallet();
  useEagerConnect();

  return async (cb = () => {}) => {
    if (Boolean(window?.ethereum)) {
      activate(walletList[0].connector, cb);
      return true;
    }
    return false;
  };
};

export const useConnectWalletConnector = () => {
  const activate = useActivateWallet();
  useEagerConnect();

  return async (cb = () => {}) => {
    activate(walletList[1].connector, cb);
  };
};

export const switchNetwork = async (
  chainInfo,
  account,
  switchToChainDECIMAL = 1
) => {
  //

  try {
    // check if the chain to connect to is installed
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + parseInt(switchToChainDECIMAL).toString(16) }], // chainId must be in hexadecimal numbers
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [chainInfo, account],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
  }

  if (web3) {
    try {
      await web3.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [chainInfo, account],
      });
    } catch (addError) {
      console.error(addError);
    }
  }
};
