import React from 'react';

// Components
import Layout from "../../components/Layout/Layout";
import CloningLaboratoryContent from "../../components/CloningLaboratoryContent/CloningLaboratoryContent";
import CloningLaboratoryBanner from "../../components/CloningLaboratoryBanner/CloningLaboratoryBanner";
import CloningLaboratoryProcess from "../../components/CloningLaboratoryProcess/CloningLaboratoryProcess";


const TreehouseGallerypage = () => {
  return (
  	<Layout>
  		<CloningLaboratoryContent/>
  		<CloningLaboratoryBanner/>
  		<CloningLaboratoryProcess/>
  	</Layout>
	) 
}

export default TreehouseGallerypage;