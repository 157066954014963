import Web3 from "web3";
// import { provider } from "web3-core";
import { UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { setWeb3Provider } from "../contracts/getContracts";

export const getLibrary = (provider) => {
  setWeb3Provider(provider);
  return new Web3(provider);
};

export const getErrorMessage = (error) => {
  if (error instanceof UnsupportedChainIdError) {
    return "Unsupported network";
  } else if (error instanceof NoEthereumProviderError) {
    return "No wallet found";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestError
  ) {
    return "Request to access account denied!";
  } else if (error.code === -32002) {
    return "Wallet connection request pending";
  } else if (error?.message) {
    return error?.message;
  } else {
    return "An error occurred";
  }
};
