// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userNfts: [],
  ogs: [],
  clones: [],
  scientists: [],
  monsters: [],
  maticMintPrice: "0",
  maticMintCount: "0",
  revealFee: "0",
  loading: "",
  userNftsOld: [],
  event: [],
  migrate: 0,
  status: {
    madScientistStolenCount: 0,
    monsterStolenCount: 0,
    totalSupply: 0,
    cloneStolenCount: 0,
    cloneCount: 0,
    madScientistCount: 0,
    monsterCount: 0,
    ogCount: 0,
  },
  baseBarMintFee: "0",
  discountEligibility: false,
  discountTxLimit: "0",
  discountTxCount: "0",
  totalBabeApeMinted: "0",
  changeNamePrice: "0",
  changeBioPrice: "0",
  changeBirthdatePrice: "0",
};

const nftContract = createSlice({
  name: "nftContract",
  initialState,
  reducers: {
    setUserNfts(state, { payload, type }) {
      if (payload && payload.length) {
        state.userNfts = payload;
      }
    },
    setUserNftsOld(state, { payload, type }) {
      // if (payload && payload.length) {
      state.userNftsOld = payload;
      // }
    },
    setNftsMeta(
      state,
      { payload: { ogs, clones, scientists, monsters }, type }
    ) {
      if (ogs && clones && scientists && monsters) {
        state.ogs = ogs;
        state.clones = clones;
        state.scientists = scientists;
        state.monsters = monsters;
      }
    },
    setNftsMaticPrice(state, { payload, type }) {
      if (payload) {
        state.maticMintPrice = payload;
      }
    },
    setLoading(state, { payload, type }) {
      state.loading = payload;
    },
    setMaticMintCount(state, { payload, type }) {
      state.maticMintCount = payload;
    },
    setBaseBarMintFee(state, { payload, type }) {
      state.baseBarMintFee = payload;
    },
    setDiscountTxCount(state, { payload, type }) {
      state.discountTxCount = payload;
    },
    setDiscountTxLimit(state, { payload, type }) {
      state.discountTxLimit = payload;
    },
    setDiscountEligibility(state, { payload, type }) {
      state.discountEligibility = payload;
    },
    setTotalBabeApeMinted(state, { payload, type }) {
      state.totalBabeApeMinted = payload;
    },
    setMigrate(state, { payload, type }) {
      state.migrate = payload?.length ?? 0;
    },
    setRevealFee(state, { payload, type }) {
      state.revealFee = payload;
    },
    setNamePrice(state, { payload, type }) {
      state.changeNamePrice = payload;
    },
    setBioPrice(state, { payload, type }) {
      state.changeBioPrice = payload;
    },
    setBirthdatePrice(state, { payload, type }) {
      state.changeBirthdatePrice = payload;
    },
    setEvent(state, { payload, type }) {
      let data = [...state.event];
      data.push(payload);
      state.event = data;
    },
    clearEvent(state, action) {
      state.event = [];
    },
    setStatus(
      state,
      {
        payload: {
          madScientistStolenCount,
          monsterStolenCount,
          totalSupply,
          cloneStolenCount,
          cloneCount,
          madScientistCount,
          monsterCount,
          ogCount,
        },
        type,
      }
    ) {
      state.status = {
        madScientistStolenCount,
        monsterStolenCount,
        totalSupply,
        cloneStolenCount,
        cloneCount,
        madScientistCount,
        monsterCount,
        ogCount,
      };
    },
  },
});

export const {
  setUserNfts,
  setNftsMeta,
  setNftsMaticPrice,
  setLoading,
  setUserNftsOld,
  setEvent,
  clearEvent,
  setStatus,
  setMigrate,
  setBaseBarMintFee,
  setDiscountEligibility,
  setDiscountTxCount,
  setDiscountTxLimit,
  setTotalBabeApeMinted,
  setMaticMintCount,
  setRevealFee,
  setNamePrice,
  setBioPrice,
  setBirthdatePrice,
} = nftContract.actions;
export default nftContract.reducer;

// incremented(state) {
//   // it's okay to do this because immer makes it immutable
//   // under the hood
//   state.value++;
// },
// amountAdded(state, action) {
//   state.value += action.payload;
// },
// // decrement
// // reset
