import React from 'react';



// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const Treehouse = (props) => {
	return(
		<section className="bp-home-treeh">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<h2>Entry Into the Treehouse</h2>
		    	<p>When you are a member of the Apevolution Club, you will be able to access this beautiful treehouse. A new home that was built in a new land after the wildfire catastrophe. In the treehouse you can claim exclusive daily $BAR rewards without staking.</p>
		    </Col>
		  </Row>
		 </Container>
		</section>
		)
}

export default Treehouse;