import React from 'react';

// Images
import HouseBannertreehouseimage from '../../assets/images/HouseBannertreehouseimage.jpg';

// Style
import "./style.css";
const TreehouseGalleryApe = (props) => {
	return(
		<section className="bp-tree-banner">
		<img src={HouseBannertreehouseimage} alt="banner"/>
		</section>
		
		)
}

export default TreehouseGalleryApe;


