import React from "react";

// Style
import { Container, Row, Col } from "reactstrap";
import { useNftContract } from "../../sms/hooks/nft-contract";
import { trunc } from "../../utils/helpers";
import "./style.css";
const MigrationStep = (props) => {
  const { account } = useNftContract();

  // console.log("migrate", migrate);
  return (
    <section className="bp-migr-sec">
      <Container>
        <Row>
          <Col md={12}>
            <h3>How to Migrate?</h3>
            <ul className="migrate_steps">
              <li>
                Holders will automatically be migrated to Polygon through
                Airdrop
              </li>
              <li>
                As long as you’re still a holder of a Baby Ape OG on ETH by 12th Apr 2022 (4am UTC), you will receive the exact Baby Ape OG on Polygon through Airdrop
              </li>
              <li>Be sure to add Polygon Mainnet onto your Metamask</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MigrationStep;
