import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// Images
import ape1 from "../../assets/images/listapeo.png";
import ape2 from "../../assets/images/listapet.png";
import ape3 from "../../assets/images/listapeth.png";
import ape4 from "../../assets/images/Group-new.png";
import Logomark from "../../assets/images/Logomark-Blue 1.png";
import cloningimg from "../../assets/images/barsymbol.png";
import apeyellow from "../../assets/images/apeyellow.png";
import Popup from "reactjs-popup";

import { useNavigate } from "react-router-dom";

// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import { useNftContract } from "../../sms/hooks/nft-contract";
import { toEther } from "../../contracts/getContracts";

const InsidetheTreehouseFilter = (props) => {
  const [displayIR, setDisplayIR] = useState("");

  const { ogs, clones, scientists, monsters, revealFee, instantReveal } =
    useNftContract();
  const navigate = useNavigate();

  const vavHandler = (state) => () => {
    state &&
      navigate("/nftpage", {
        state,
      });
  };

  const handlerIR = (id) => () => {
    setDisplayIR(id);
  };

  const handlerInstantReveal = async () => {
    displayIR !== "" && (await instantReveal(displayIR));
    setDisplayIR("");
  };

  return (
    <section className="bp-itthfi-main">
      <Container fluid>
        <Row>
          <Col md={7}></Col>
          <Col md={5}>
            <Tabs className="bp-itthfi-btntab">
              <TabList className="bp-itthfi-btnlist">
                <Tab className="bp-itthfi-btn">OG</Tab>
                <Tab className="bp-itthfi-btn">Clone</Tab>
                <Tab className="bp-itthfi-btn">Mad Scientist</Tab>
                <Tab className="bp-itthfi-btn">Monster</Tab>
              </TabList>

              <TabPanel>
                <ul className="bp-itthfi-apli">
                  {ogs &&
                    ogs.map((og, idx) => (
                      <li
                        onClick={
                          og?.visibility
                            ? vavHandler(og)
                            : handlerIR(og?.tokenId)
                        }
                        key={og?.tokenId}
                      >
                        <div className="bp-itthfi-ape">
                          <img src={og.image} alt="ape" />
                          <p>#{og?.tokenId}</p>
                        </div>
                      </li>
                    ))}
                  {/* <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li> */}
                  {/* <li>
                    
                  </li> */}
                  {/* <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li> */}
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="bp-itthfi-apli">
                  {clones &&
                    clones.map((clone, idx) => (
                      <li
                        onClick={
                          clone?.visibility
                            ? vavHandler(clone)
                            : handlerIR(clone?.tokenId)
                        }
                        key={clone.tokenId}
                      >
                        <div className="bp-itthfi-ape">
                          <img src={clone.image} alt="ape" />
                          <p>#{clone.tokenId}</p>
                        </div>
                      </li>
                    ))}
                  {/* <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li> */}
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="bp-itthfi-apli">
                  {scientists &&
                    scientists.map((sc, idx) => (
                      <li
                        onClick={
                          sc?.visibility
                            ? vavHandler(sc)
                            : handlerIR(sc?.tokenId)
                        }
                        key={sc.tokenId}
                      >
                        <div className="bp-itthfi-ape">
                          <img src={sc.image} alt="ape" />
                          <p>#{sc.tokenId}</p>
                        </div>
                      </li>
                    ))}

                  {/* <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li> */}
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="bp-itthfi-apli">
                  {monsters &&
                    monsters.map((mon, idx) => (
                      <li
                        onClick={
                          mon?.visibility
                            ? vavHandler(mon)
                            : handlerIR(mon?.tokenId)
                        }
                        key={mon.tokenId}
                      >
                        <div className="bp-itthfi-ape">
                          <img src={mon.image} alt="ape" />
                          <p>#{mon.tokenId}</p>
                        </div>
                      </li>
                    ))}
                  {/* <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape1} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape2} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li>
                  <li>
                    <div className="bp-itthfi-ape">
                      <img src={ape3} alt="ape" />
                      <p>#2198</p>
                    </div>
                  </li> */}
                </ul>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
        <Popup
          // trigger={
          //   <div className="bp-itthfi-ape">
          //     <img src={ape4} alt="ape" />
          //     <p>#2198</p>
          //   </div>
          // }
          modal
          nested
          open={displayIR !== ""}
        >
          {(close) => (
            <div className="modal">
              <button className="close" onClick={() => setDisplayIR("")}>
                &times;
              </button>
              <div className="cl-successpopup">
                <div className="header">Incubator</div>
                <div className="content">
                  <div className="cl-succ-inner">
                    <div className="cl-ape-img">
                      <div className="cl-ape-img-frame v1">
                        <img src={ape4} alt="ape" className="" />
                      </div>
                    </div>
                    <div className="cl-succ-mess">
                      <div className="row">
                        <div className="col-md-6">
                          <span class="bp-tree-apena-modal">Token Id</span>
                          <h2 className="modal-heading1">#{displayIR}</h2>
                        </div>
                        <div className="col-md-6">
                          <span class="bp-tree-apena-modal">Type</span>
                          <h2 className="modal-heading1">Incubator</h2>
                        </div>
                        <div className="money-img">
                          <strong>Cost</strong>
                          <img src={cloningimg} alt="cloninglaboratory" />
                          <b>{toEther(revealFee)}</b>
                        </div>
                        <a
                          href="#."
                          onClick={handlerInstantReveal}
                          rel="noreferrer"
                          className="bp-treepg-popbtn-modal"
                        >
                          Incubate
                        </a>
                        <p className="small-para">
                          Clones will hatch after 72 hours or you can instantly
                          hatch now for just {toEther(revealFee)} $BAR.
                        </p>
                      </div>

                      {/*<ul className="bp-itthfi-apli-modal">
                            <li>
                              <span class="bp-tree-apena-modal">Token Id</span>
                              <h3 className="modal-heading">#2876</h3>
                            </li>
                            <li>
                              <span class="bp-tree-apena-modal">Type</span>
                              <h3 className="modal-heading">Incubator</h3>
                            </li>
                          </ul> 
                           <p>
                            <strong>Cost</strong>
                            <img src={cloningimg} alt="cloninglaboratory" />
                            22
                          </p>
                          <div className="cl-btn1">
                            <a href="https://opensea.io/collection/babyapesrescue-bar" 
                            target="_blank" rel="noreferrer" className="bp-treepg-popbtn-modal">
                          Incubate
                        </a>
                          </div>
                          <p>
                           Clones will hatch after 72 hours or you can instantly hatch now for just 15 $BAR.
                          </p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>
      </Container>
    </section>
  );
};

export default InsidetheTreehouseFilter;
