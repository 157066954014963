import React from 'react';

// Components
import Layout from "../../components/Layout/Layout";

import { Container,Row, Col} from 'reactstrap';

const PrivacyPolicy = () => {
  return (
  	<Layout>
  		<section className="bp-ap-res useful-links">
			<Container>
			  	<Row>
				    <Col md={12}>
				    	<h2>Privacy Policy</h2>
				    </Col>
			    </Row>
			    <Row>
			    	<Col md={12}>
			    		<p>
			    			Welcome to Baby Apes Rescue (“BAR”), a digital non-fungible token (“NFT”). BAR is a decentralized application and digital assets platform operating on the ethereum blockchain. BAR uses specially-developed smart contracts that enable the purchasing of digital creations on a peer-to-peer digital marketplace. This privacy policy (“Policy”) describes how BAR and its related companies (“we”) collect, use and share personal information of consumer users of this website, www.BabyApesRescue.com (the “Website”). This Policy also applies to any of our other websites that post this Policy. We may change this privacy policy at any time. If we make any changes, we will change the Last Updated date above.
			    		</p>

						<h3>Collection of Personal Information</h3>
						<p>We may collect information that you provide to us directly on our Website. We may also automatically log information about you and your computer. For example, when visiting our Website, we may log your computer operating system type, browser type, browser language, the web pages you viewed on the Website, how long you spent on a page of the Website, and/or access times and information about your use of and actions on our Website.
						</p>

						<h3>Use of Personal information</h3>
						<p>We may use your personal information for any of the following purposes: (a) to operate, maintain, and improve our Website, products, and services; (b) to send information including confirmations, technical notices, updates, security alerts, and support and administrative messages regarding our Website and its services; (c) to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity; and/or (d) to provide and deliver products and services via the Website.</p>

						<h3>Sharing of Personal Information</h3>
						<p>We may share personal information for legal, protection, and safety purposes, including, but not limited to: (a) compliance with laws; (b) responding to lawful requests and legal processes; and/or (c) protecting the rights and property of BAR and its agents, customers, and others, such as enforcing our agreements, policies, and terms of use.</p>


			    	</Col>
			  	</Row>
		 	</Container>
		</section>
  	</Layout>
	) 
}

export default PrivacyPolicy;