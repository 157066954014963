import React from 'react';

// Components
import Layout from "../../components/Layout/Layout";
import TreehouseGallery from "../../components/TreehouseGallery/TreehouseGallery";
import TreehouseGalleryBanner from "../../components/TreehouseGalleryBanner/TreehouseGalleryBanner";
import TreehouseGalleryApe from "../../components/TreehouseGalleryApe/TreehouseGalleryApe";


const TreehouseGallerypage = () => {
  return (
  	<Layout>
  		<TreehouseGallery/>
  		<TreehouseGalleryBanner/>
  		<TreehouseGalleryApe/>
  	</Layout>
	) 
}

export default TreehouseGallerypage;