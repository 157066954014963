import React from 'react';

import { Container,Row, Col} from 'reactstrap';
// Style
import "./style.css";
const Migrationcontent = (props) => {
	return(
		<section className="bp-migr-main">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<h2>Migration</h2>
		    	<h3>Why Should You Migrate Your Baby Apes?</h3>
		    	<ul>
					<li>Migrated Baby Apes will be able to yield 3 banana $BAR per day</li>
					<li>$BAR can be used to clone other baby ape species which can yield additional $BAR, display your nft on our treehouse gallery, change bio and birthdate. After migration, you’ll immediately receive 30 $BAR.</li>
				</ul>
		    </Col>
		  </Row>
		 </Container>
		</section>
		
		)
}

export default Migrationcontent;


