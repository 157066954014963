import { useDispatch, useSelector } from "react-redux";
import {
  babyApeContract,
  babyApeOldContract,
  switchNetwork,
  toWei,
} from "../../contracts/getContracts";
import { useCustomWeb3React } from "../../hooks/useCustomWeb3React";
import {
  ETHEREUM_DATA,
  HEROKU_NFT,
  POLYGON_CHAIN_ID,
} from "../../utils/constants";
import {
  clearEvent,
  setBaseBarMintFee,
  setDiscountEligibility,
  setDiscountTxCount,
  setEvent,
  setLoading,
  setMigrate,
  setNftsMaticPrice,
  setNftsMeta,
  setStatus,
  setUserNfts,
  setUserNftsOld,
  setDiscountTxLimit,
  setTotalBabeApeMinted,
  setMaticMintCount,
  setRevealFee,
  setNamePrice,
  setBioPrice,
  setBirthdatePrice,
} from "../slices/nft-contract";

export const useNftContract = () => {
  const { account, chainId } = useCustomWeb3React();
  const contract = babyApeContract();
  // const contractOld = babyApeOldContract();
  const dispatch = useDispatch();

  const nftContractState = useSelector((state) => state.nft);

  const polygonChain = (_switch = false) => {
    if (!contract || !account) return;
    if (parseInt(chainId) !== POLYGON_CHAIN_ID) {
      _switch &&
        switchNetwork(ETHEREUM_DATA, account, parseInt(POLYGON_CHAIN_ID));
      return;
    }
    return true;
  };

  const isNftsExist = async () => {
    try {
      if (!polygonChain()) return;
      const nfts = await contract.methods.walletOfOwner(account).call();
      return nfts ? (nfts.length > 0 ? true : false) : false;
    } catch {
      return false;
    }
  };

  const loadUserNfts = async () => {
    if (!polygonChain()) return;
    try {
      const nfts = await contract.methods.walletOfOwner(account).call();
      const price = await contract.methods.maticMintFee().call();

      let metas = await fetch(`${HEROKU_NFT}[${nfts.join(",")}]`);
      // Promise.all(
      //   nfts.map((id) => fetch(`${HEROKU_NFT}${id}`))
      // );

      let metasJSON = await metas.json();
      // Promise.all(metas.map((meta) => meta.json()));

      let ogs = [];
      let clones = [];
      let scientists = [];
      let monsters = [];

      metasJSON.forEach(async (meta, idx) => {
        if (meta.image && meta.image.includes("ipfs://")) {
          meta.image = meta.image.replace(
            "ipfs://",
            "https://gateway.pinata.cloud/ipfs/"
          );
        }

        const type = meta?.attributes?.find(
          (x) => x.trait_type === "Type"
        )?.value;

        if (parseInt(meta.tokenId) <= 2500) {
          ogs.push({ ...meta });
        } else if (type === "Baby Ape Clone") {
          clones.push({ ...meta });
        } else if (type === "Mad Scientist Ape") {
          scientists.push({ ...meta });
        } else if (type === "Monster Ape") {
          monsters.push({ ...meta });
        }
        //temp
        else {
          ogs.push({ ...meta });
        }
      });

      dispatch(setUserNfts(nfts));
      dispatch(setNftsMaticPrice(price));
      dispatch(
        setNftsMeta({
          ogs,
          clones,
          scientists,
          monsters,
        })
      );
    } catch {}
  };

  const loadGameStatus = async () => {
    if (!polygonChain()) return;

    try {
      const { cloneCount, madScientistCount, monsterCount, ogCount } =
        await contract.methods.getTypeCounts().call();
      const madScientistStolenCount = await contract.methods
        .madScientistStolenCount()
        .call();
      const monsterStolenCount = await contract.methods
        .monsterStolenCount()
        .call();
      const totalSupply = await contract.methods.totalSupply().call();
      const cloneStolenCount = await contract.methods.cloneStolenCount().call();

      dispatch(
        setStatus({
          madScientistStolenCount,
          monsterStolenCount,
          totalSupply,
          cloneStolenCount,
          cloneCount,
          madScientistCount,
          monsterCount,
          ogCount,
        })
      );
    } catch {}
  };

  const tipBabyApeHolder = async (id, amountBar) => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods
        .tipBabyApeHolder(id, toWei(amountBar.toString()))
        .send({
          from: account,
        });
    } catch {}
  };

  const instantReveal = async (id) => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods.instantReveal(id).send({
        from: account,
      });
    } catch {}
  };

  const barMintFeeBase = async () => {
    if (!polygonChain()) return;
    try {
      let fee = await contract.methods.getBarBurnAmount(1).call();
      dispatch(setBaseBarMintFee(fee));
    } catch {}
  };

  const getChangeNamePrice = async () => {
    if (!polygonChain()) return;
    try {
      let price = await contract.methods.nameChangePrice().call();
      dispatch(setNamePrice(price));
    } catch {}
  };
  const getChangeBioPrice = async () => {
    if (!polygonChain()) return;
    try {
      let price = await contract.methods.bioChangePrice().call();
      dispatch(setBioPrice(price));
    } catch {}
  };
  const getChangeBirthdatePrice = async () => {
    if (!polygonChain()) return;
    try {
      let price = await contract.methods.birthdateChangePrice().call();
      dispatch(setBirthdatePrice(price));
    } catch {}
  };

  const getMaticMintCount = async () => {
    if (!polygonChain()) return;
    try {
      let count = await contract.methods.maticMintCount().call();
      dispatch(setMaticMintCount(count));
    } catch {}
  };

  const getRevealFee = async () => {
    if (!polygonChain()) return;
    try {
      let fee = await contract.methods.revealFee().call();
      dispatch(setRevealFee(fee));
    } catch {}
  };

  const getDiscountEligiblity = async () => {
    if (!polygonChain()) return;
    try {
      let eligibility = await contract.methods
        .getDiscountEligibility(account)
        .call();
      dispatch(setDiscountEligibility(eligibility));
    } catch {}
  };

  const getDiscountAvailTxCount = async () => {
    if (!polygonChain()) return;
    try {
      let txCount = await contract.methods.discountAvailTxCount(account).call();
      dispatch(setDiscountTxCount(txCount));
    } catch {}
  };

  const getDiscountTxLimit = async () => {
    if (!polygonChain()) return;
    try {
      let txLimit = await contract.methods.discountTxLimit().call();
      dispatch(setDiscountTxLimit(txLimit));
    } catch {}
  };

  const getTotalBabeApeMinted = async () => {
    if (!polygonChain()) return;
    try {
      let totalSupply = await contract.methods.totalSupply().call();
      dispatch(setTotalBabeApeMinted(totalSupply));
    } catch {}
  };

  const mintNFT = async (price, count = 1) => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    dispatch(setLoading("mint"));
    try {
      if (price) {
        price = await contract.methods.maticMintFee().call();

        await contract.methods.mintMatic(count).send({
          from: account,
          value: parseFloat(price) * count,
        });
        dispatch(setLoading("success"));
        loadUserNfts();
        loadGameStatus();
      } else {
        await contract.methods.mint(count).send({
          from: account,
        });
        dispatch(setLoading("success"));
        loadUserNfts();
        loadGameStatus();
      }
    } catch {
      dispatch(setLoading(""));
    }
  };

  const changeName = async (tokenId, name) => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods.changeName(tokenId, name).send({
        from: account,
      });
    } catch {}
  };

  const changeBio = async (tokenId, bio) => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods.changeBio(tokenId, bio).send({
        from: account,
      });
    } catch {}
  };

  const changeBirthdate = async (tokenId, dateTimpestamp) => {
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    if (!polygonChain(true)) return;

    try {
      await contract.methods.changeBirthdate(tokenId, dateTimpestamp).send({
        from: account,
      });
    } catch {}
  };

  const clearLoading = () => {
    dispatch(setLoading(""));
    dispatch(clearEvent(""));
  };

  const addEventInfo = (isSteal, tokenId, minter) => {
    dispatch(
      setEvent({
        isSteal,
        tokenId,
        minter,
      })
    );
  };

  // const getOldUserNfts = async () => {
  //   if (!ethChain()) return;

  //   try {
  //     const nfts = await contractOld.methods.walletOfOwner(account).call();
  //     console.log("nfts", nfts);
  //     dispatch(setUserNftsOld(nfts));
  //   } catch {}
  // };

  // const burnNfts = async () => {
  //   if (!ethChain(true)) return;

  //   try {
  //     const nfts = await contractOld.methods.walletOfOwner(account).call();
  //     // console.log("nfts", nfts);
  //     nfts.forEach(async (nftId) => {
  //       try {
  //         await contractOld.methods
  //           .transferFrom(account, ZERO_ADDRESS, nftId)
  //           .send({ from: account });
  //         await getOldUserNfts();
  //       } catch {}
  //     });
  //   } catch {}
  // };

  const updateMigrate = async () => {
    // dispatch(setMigrate([]));
    // if (!contract || !account || parseInt(chainId) !== POLYGON_CHAIN_ID) return;
    // try {
    //   let res = await fetch(`${HEROKU_CLAIM}${account}`);
    //   let resJSON = await res.json();
    //   dispatch(setMigrate(resJSON?.tokenId ?? 0));
    // } catch {}
  };

  // const mintOnPolygon = async () => {
  //   if (!polygonChain(true)) return;
  //   try {
  //     let res = await fetch(`${HEROKU_CLAIM}${account}`);
  //     let resJSON = await res.json();
  //     console.log(resJSON.userAddress, resJSON.tokenId, resJSON.signature);
  //     await contract.methods
  //       .migrateMint(resJSON.userAddress, resJSON.tokenId, resJSON.signature)
  //       .send({
  //         from: account,
  //       });

  //     loadGameStatus();
  //     loadUserNfts();
  //   } catch {}
  // };

  return {
    loadUserNfts,
    // burnNfts,
    mintNFT,
    clearLoading,
    changeName,
    // mintOnPolygon,
    // getOldUserNfts,
    changeBio,
    changeBirthdate,
    addEventInfo,
    loadGameStatus,
    tipBabyApeHolder,
    updateMigrate,
    getDiscountAvailTxCount,
    getDiscountEligiblity,
    getDiscountTxLimit,
    barMintFeeBase,
    getTotalBabeApeMinted,
    isNftsExist,
    getMaticMintCount,
    getRevealFee,
    instantReveal,
    getChangeNamePrice,
    getChangeBirthdatePrice,
    getChangeBioPrice,
    ...nftContractState,
    account,
    chainId,
  };
};
