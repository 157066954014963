import React from "react";

// Images
import bar from "../../assets/images/barsymbolitth.png";
import greenbann from "../../assets/images/greenbann.png";

// Style
import { Container, Row, Col } from "reactstrap";
import "./style.css";
import { useNftContract } from "../../sms/hooks/nft-contract";
import { useTokenContract } from "../../sms/hooks/token-contract";
const InsidetheTreehouseGameStatus = (props) => {
  const {
    status: {
      madScientistStolenCount,
      monsterStolenCount,
      totalSupply,
      cloneStolenCount,
      cloneCount,
      madScientistCount,
      monsterCount,
      ogCount,
    },
  } = useNftContract();
  const { totalSupply: bar } = useTokenContract();
  return (
    <section className="bp-itthgs">
      <Container>
        <Row>
          <Col md={12}>
            <div className="bp-itthgs-main">
              <h1>Game Status</h1>
              <div className="bp-itthgs-inner">
                <div className="bp-itthgs-insec">
                  <p>Total Minted: {totalSupply}</p>
                  <p>Baby Ape OG: {ogCount}</p>
                  <p>Baby Ape Clone: {cloneCount}</p>
                </div>

                <div className="bp-itthgs-insec">
                  <p>Monster Ape: {monsterCount}</p>
                  <p>Mad Scientist Ape: {madScientistCount}</p>
                  <p>Baby Ape Clone Stolen: {cloneStolenCount}</p>
                </div>

                <div className="bp-itthgs-insec">
                  <p>Monster Ape Stolen: {monsterStolenCount}</p>
                  <p>Mad Scientist Ape Stolen: {madScientistStolenCount}</p>
                  <p>Circulating $BAR Supply: {bar}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InsidetheTreehouseGameStatus;
