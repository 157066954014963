import React from 'react';

// Images
import BarIcon1 from '../../assets/images/baricon1.png';
import BarIcon2 from '../../assets/images/baricon2.png';
import BarIcon3 from '../../assets/images/baricon3.png';
import BarIcon4 from '../../assets/images/baricon4.png';

// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";

const BananaBARSmartContract = (props) => {
 

  return (
   <section className="bp-bnbarmi-inner bp-bnbarmi-main">
     <Container className="bp-bnbarmi-itcon">
      <Row>
        <Col md={12}>
          <h4><span>Banana</span> $BAR Polygon Contract:</h4>
          <p className="polycontract_text"><a href="https://polygonscan.com/address/0xdae5589279d8facb9657740b6cb1826876f8a5e5" target="_blank" rel="noreferrer" className="polycontract_link d-block">
            0xdae5589279d8facb9657740b6cb1826876f8a5e5
          </a></p>
          
        </Col>
      </Row>
     </Container>

    </section>
    
  );
};

export default BananaBARSmartContract;
