import React from "react";

// Components
import Layout from "../../components/Layout/Layout";

import NFTPagecontent from "../../components/NFTPage/NFTPage";

const NFTPage = () => {
  return (
    <Layout>
      <NFTPagecontent />
    </Layout>
  );
};

export default NFTPage;
