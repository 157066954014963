import React from 'react';
import Popup from 'reactjs-popup';


// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const CloningLaboratoryContent = (props) => {
	return(
		<section className="bp-clta-main">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<h1>Cloning Laboratory</h1>
		    	<p>To clone, scroll down below and use one of the boxes below. You’ll require a certain amount of $BAR or MATIC</p>
		    </Col>
		  </Row>
		 </Container>


		</section>
		
		)
}

export default CloningLaboratoryContent;


