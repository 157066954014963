import React from 'react';


// video
import video from '../../assets/video/CloneLab.mp4';

// Style
import "./style.css";
const CloningLaboratoryBanner = (props) => {
	return(
		<section>
		<div className="bp-clla-vidbanner">
			<video width="" height="" loop autoPlay muted>
			  <source src={video} type="video/mp4"/>
			</video>
		</div>
		</section>
		
		)
}

export default CloningLaboratoryBanner;


