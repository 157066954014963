import React, { useEffect, useState } from "react";

// Components
import Layout from "../../components/Layout/Layout";

import InsidetheTreehousecontent from "../../components/InsidetheTreehouse/InsidetheTreehouse";
import InsidetheTreehouseFilter from "../../components/InsidetheTreehouseFilter/InsidetheTreehouseFilter";
import InsidetheTreehouseGameStatus from "../../components/InsidetheTreehouseGameStatus/InsidetheTreehouseGameStatus";

const InsidetheTreehouse = () => {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  // if (loading) {
  //   return <>Loading...</>;
  // }
  return (
    <Layout>
      <InsidetheTreehousecontent />
      <InsidetheTreehouseFilter />
      <InsidetheTreehouseGameStatus />
    </Layout>
  );
};

export default InsidetheTreehouse;
