import React from 'react';

// Images
import BarIcon1 from '../../assets/images/baricon1.png';
import BarIcon2 from '../../assets/images/baricon2.png';
import BarIcon3 from '../../assets/images/baricon3.png';
import BarIcon4 from '../../assets/images/baricon4.png';

// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const BananaBARMoreInfo = (props) => {
	return(
		<section className="bp-bnbarmi-main">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<h2>More Info About $BAR</h2>
		    	
		    </Col>
		  </Row>
		 </Container>
		 <Container>
		  <Row>
		    <Col md={6} className="bp-bnbarmi-inner">
			    <div className="bp-bnbarmi-inner-sec">
			    	<img src={BarIcon1} alt="BarIcon"/>
			    	<h3>Yield</h3>
			    	<p>Each Baby Ape OG will be eligible to claim <span>3 $BAR</span> tokens per day. Each Baby Ape Clone & Mad Scientist will be eligible to claim <span>1.5 $BAR tokens</span> per day. Baby Ape Monsters will be able to steal <span>20% $BAR tokens</span> claimed by Baby Ape OG, Clones & Mad Scientist.</p>
			    </div>
		    </Col>
		    <Col md={6} className="bp-bnbarmi-inner">
		    	<div className="bp-bnbarmi-inner-sec">
			    	<img src={BarIcon2} alt="BarIcon"/>
			    	<h3>Customise</h3>
			    	<p>$BAR can be used to change the Name/Bio/Birthdate of Baby Apes OG, Baby Apes Clone, Mad Scientists and Monsters. Name change and birthdate change requires <span>60 $BAR</span>. Bio change requires <span>40 $BAR</span>. Once the change is successful, it’ll be immediately be on the blockchain.</p>
			    </div>
			</Col>

			<Col md={6} className="bp-bnbarmi-inner">
		    	<div className="bp-bnbarmi-inner-sec">
			    	<img src={BarIcon3} alt="BarIcon"/>
			    	<h3>Treehouse Gallery Display</h3>
			    	<p>Want to display your Baby Ape OG, Clone, Mad Scientist or Monster on our public Treehouse Gallery? Just use <span>60 $BAR</span> and your nft will be displayed! Checkout the treehouse gallery here.</p>
			    </div>
			</Col>

			<Col md={6} className="bp-bnbarmi-inner">
		    	<div className="bp-bnbarmi-inner-sec">
			    	<img src={BarIcon4} alt="BarIcon"/>
			    	<h3>Clone</h3>
			    	<p>With just Banana $BAR you’ll be able to clone a new baby ape nft. If you hold at least one baby ape OG, you’ll be able to get <span>10% off</span> the required $BAR amount. To start cloning, go to this page.</p>
			    </div>
			</Col>

		  </Row>
		 </Container>

		 <Container className="bp-bnbarmi-itcon">
		  <Row>
		    <Col md={12}>
		    	<p>$BAR is NOT an investment and has NO economic value. It is a utility token that fuels the Baby Apes Rescue ecosystem. Each Baby Ape OG will be eligible to claim 3 $BAR tokens per day. Each Baby Ape Clone & Mad Scientist will be eligible to claim 1.5 $BAR tokens per day. Baby Ape Monsters will be able to steal 20% $BAR tokens claimed by Baby Ape OG, Clones & Mad Scientist.</p>
				<p>Baby Ape OG holders will be able to claim 30 $BAR upon migration. The maximum supply of BAR token is 10,000,000.
				</p>
		    	
		    </Col>
		  </Row>
		 </Container>

		</section>
		
		)
}

export default BananaBARMoreInfo;


