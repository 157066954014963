import React from 'react';

// Images
import about1 from '../../assets/images/about-1.jpg';
import about2 from '../../assets/images/about-2.png';
import about3 from '../../assets/images/about-3.jpg';
import about4 from '../../assets/images/about-4.jpg';

// Style
import { Container,Row, Col} from 'reactstrap';
import "./ApesRescue.css";
const ApesRescue = (props) => {
	return(
		<section className="bp-ap-res" id="about">
		<Container>

		  <Row>
		    <Col md={7}>
		    	<div className="bp-ap-res-cont">
				<h2>About <span className="br">Apevolution Club</span></h2>
				<p>Apevolution club was previously known as Baby Apes Rescue. The rename marks the start of a new era of Apes.</p>
                <p>It began when a giant asteroid abruptly struck a volcano at the home of Baby Apes. The impact caused a volcano eruption which quickly turned into a massive forest fire. A total of 2500 baby apes were at risk of extinction but a group of apes from another tribe rescued them in time.</p>
                <p>Amongst the survivors of the catastrophe was a Mad Scientist Ape who became obsessed with cloning himself to save his own kind for fear that another disaster may strike. He created a laboratory in his new home and began daily experimentation on different cloning methods in an attempt to clone his kind. On a fateful day, while conducting one of his many cloning experiments, he accidentally created a couple of strange species apart from his own kind.</p>
		    	</div>
		    </Col>
		    <Col md={5}>
			    <div className="bp-ap-res-img">
			    	<div className="bp-ap-res-img-inner">
						<img src={about1} alt="About"/>
						<img src={about2} alt="About"/>
			    	</div>
			    	<div className="bp-ap-res-img-inner">
						<img src={about3} alt="About"/>
						<img src={about4} alt="About"/>
			    	</div>

			    </div>
		    </Col>
		  </Row>

		</Container>
			
		</section>
		)
}

export default ApesRescue;