export const CONTRACT_ADDRESSES = {
  babyApeNFT: "0xFF35D2AAa7E7C5E3aD0D81d7E621086C642ab74d",
  babyApeNFTOld: "0x5Bf4DDBC36694C6B7A59C0FAf2BC663c12827125",
  bananaToken: "0xDAe5589279d8FaCB9657740b6cb1826876f8A5E5",
};

export const ZERO_ADDRESS = "0x000000000000000000000000000000000000dEaD";
export const MAX_UINT256 =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const POLYGON_CHAIN_ID = 137;

export const POLYGON_WSS =
  "wss://polygon-mainnet.g.alchemy.com/v2/2qVpTEPVHbwUzUdlHqHd8UPqdVVl6oI9";

export const EVENT_WSS =
  "wss://polygon-mainnet.g.alchemy.com/v2/2qVpTEPVHbwUzUdlHqHd8UPqdVVl6oI9";

export const POLYGON_HTTPS =
  "https://polygon-mainnet.g.alchemy.com/v2/2qVpTEPVHbwUzUdlHqHd8UPqdVVl6oI9";

export const HEROKU_NFT = "https://api.apevolutionclub.com/nfts/";

export const SUBGRAPH_URI_POLYGON =
  "https://api.thegraph.com/subgraphs/name/nabeelimran/mint-event-listener";

export const POLYGON_DATA = {
  name: "Polygon Mainnet",
  chain: "Polygon",
  rpc: [
    "https://polygon-rpc.com/",
    "https://rpc-mainnet.matic.network",
    "https://matic-mainnet.chainstacklabs.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
    "https://matic-mainnet-full-rpc.bwarelabs.com",
  ],
  faucets: [],
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  infoURL: "https://polygon.technology/",
  shortName: "MATIC",
  chainId: 137,
  networkId: 137,
  slip44: 966,
  explorers: [
    {
      name: "polygonscan",
      url: "https://polygonscan.com",
      standard: "EIP3091",
    },
  ],
};
// {
//   name: "Polygon Testnet Mumbai",
//   chain: "Polygon",
//   rpc: [
//     "https://matic-mumbai.chainstacklabs.com",
//     "https://rpc-mumbai.maticvigil.com",
//     "https://matic-testnet-archive-rpc.bwarelabs.com",
//   ],
//   faucets: ["https://faucet.polygon.technology/"],
//   nativeCurrency: {
//     name: "MATIC",
//     symbol: "MATIC",
//     decimals: 18,
//   },
//   infoURL: "https://polygon.technology/",
//   shortName: "maticmum",
//   chainId: 80001,
//   networkId: 80001,
//   explorers: [
//     {
//       name: "polygonscan",
//       url: "https://mumbai.polygonscan.com",
//       standard: "EIP3091",
//     },
//   ],
// };

export const ETHEREUM_DATA = {
  name: "Ethereum Mainnet",
  chain: "ETH",
  icon: "ethereum",
  rpc: [
    "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
    "wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
    "https://api.mycryptoapi.com/eth",
    "https://cloudflare-eth.com",
  ],
  faucets: [],
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
  },
  infoURL: "https://ethereum.org",
  shortName: "eth",
  chainId: 1,
  networkId: 1,
  slip44: 60,
  ens: {
    registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
  },
  explorers: [
    {
      name: "etherscan",
      url: "https://etherscan.io",
      standard: "EIP3091",
    },
  ],
};
// {
//   name: "Ethereum Mainnet",
//   chain: "ETH",
//   icon: "ethereum",
//   rpc: [
//     "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
//     "wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
//     "https://api.mycryptoapi.com/eth",
//     "https://cloudflare-eth.com",
//   ],
//   faucets: [],
//   nativeCurrency: {
//     name: "Ether",
//     symbol: "ETH",
//     decimals: 18,
//   },
//   infoURL: "https://ethereum.org",
//   shortName: "eth",
//   chainId: 1,
//   networkId: 1,
//   slip44: 60,
//   ens: {
//     registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
//   },
//   explorers: [
//     {
//       name: "etherscan",
//       url: "https://etherscan.io",
//       standard: "EIP3091",
//     },
//   ],
// };
