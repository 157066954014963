import React from 'react';


import roadmap from '../../assets/images/roadmap-img.png';
// Style
import { Container,Row, Col} from 'reactstrap';
import "./style.css";
const Roadmap = (props) => {
	return(
		<section className="bp-home-roadmap" id="roadmap">
		<Container>
		  <Row>
		    <Col md={12}>
		    	<h2>Roadmap</h2>
		    	
		    </Col>
		  </Row>
		  <Row>
		    <Col md={12}>
		    	<div className="rm_box_mainsec">
			<div className="rm_box_maindiv"></div>


			<div  className="rm_box_main">
				<div className="rm_box_heading">
				<div className="ba-ph">Phase 1</div>
				</div>
				
				<div className="rm_box">
					<div className="rm_box-inner line-through">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Initial sale of Baby Apes Rescue NFT collection
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Community raffle to win Baby Apes which will be airdropped for free (in progress)
						</p>
					</div>
					<div className="rm_box-inner line-through">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Song Animation Video for Baby Apes Rescue NFT
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Launch of Merchandise Store for Baby Apes Rescue NFT collection
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Community raffle amongst baby ape holders to win up to 0.8 ETH (in progress)
						</p>
					</div>
					<div className="rm_box-inner line-through">
						<img src={roadmap} alt="roadmap"/>
						<p>
						We make a donation of $5,000 to a Global Climate Change Initiative
						</p>
					</div>
				</div>
			</div>
			<div  className="rm_box_main">
				<div className="rm_box_heading">
				<div className="ba-ph">Phase 1.5</div>
				</div>
				
				<div className="rm_box">
					<div className="rm_box-inner line-through">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Implementation of $BAR token
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Baby Apes Rescue NFT will be able to yield $BAR rewards daily ($BAR is NOT an investment and has NO economic value, it is a utility token to be used within the Baby Apes Ecosystem)
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						$BAR token can be used to change the name/bio/birthdate of your baby ape, as well as to clone their Ape to generate more $BAR token daily
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Baby Apes Rescue NFT holders will be able to clone their Apes which allows holders to yield additional $BAR daily
						</p>
					</div>
					<div className="rm_box-inner line-through">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Treehouse Entry Access Version 1.0
						</p>
					</div>
				</div>
			</div>

			<div  className="rm_box_main">
				<div className="rm_box_heading">
				<div className="ba-ph">Phase 2</div>
				</div>
				
				<div className="rm_box">
					<div className="rm_box-inner line-through">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Discord server & project rebranding in preparation for Apevolution
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Apevolution 1.0 Event & Collection Launch
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						We will launch a claiming option for Baby Ape Holders to get a Special Asteroid NFT for the Apevolution Event
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Upon close contact with the asteroid, it will cause genetic mutation within the baby ape causing it to evolve into something else!
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Expand $BAR token utility
						</p>
					</div>
				</div>
			</div>

			<div  className="rm_box_main">
				<div className="rm_box_heading">
				<div className="ba-ph">Phase 2.5</div>
				</div>
				
				<div className="rm_box">
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Entry into the Metaverse, whereby a new beautiful giant treehouse is constructed for both baby ape and apevolution collection where you can display your NFTs proudly!
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						50% of royalties from secondary sales from Baby Apes (if you hold baby apes = 50% royalties)
						</p>
					</div>
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Implementation of a Cloning Laboratory on our website
						</p>
					</div>
				</div>
			</div>

			<div  className="rm_box_main">
				<div className="rm_box_heading">
				<div className="ba-ph">Phase 3</div>
				</div>
				
				<div className="rm_box">
					<div className="rm_box-inner">
						<img src={roadmap} alt="roadmap"/>
						<p>
						Expansion of the metaverse to include games and event that the community can participate
						</p>
					</div>
					
				</div>
			</div>

			<div className="rm_boxsec">
					<p>
					Do note that this may be subject to changes as we progress further down the roadmap.
					</p>
				</div>
			</div>
		    	
		    </Col>
		  </Row>
		 </Container>
		</section>
		)
}

export default Roadmap;